import React, { useCallback } from 'react'
import { useBoardVersionsForEntity } from '@fintastic/shared/ui/components'
import { IconButton, MenuItem, Popover, Tooltip } from '@mui/material'
import { HistoryIcon } from '@fintastic/shared/ui/icons'
import { ArrowDropDown } from '@mui/icons-material'
import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'

export const EntityLevelHistoryMultiversionButton: React.FC<{
  entityId: string
  disabled: boolean
}> = ({ entityId, disabled }) => {
  const boardVersionsOptions = useBoardVersionsForEntity(entityId)
  const options = boardVersionsOptions.options
  const historyApi = useHistoryLogGlobalApi()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl) && !disabled

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled || !event) {
        return
      }
      setAnchorEl(event.currentTarget)
    },
    [disabled],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOpenHistory = useCallback(
    (versionId: string) => {
      historyApi?.openOnEntityLevel({
        versionId,
        entityId,
      })
      handleClose()
    },
    [entityId, historyApi, handleClose],
  )

  const derivedDisabled = boardVersionsOptions.isLoading || disabled

  const tooltipText = 'Open history'

  if (options.length === 1) {
    return (
      <Tooltip
        enterNextDelay={700}
        enterDelay={700}
        leaveDelay={0}
        title={tooltipText}
        disableInteractive={true}
        placement="top"
      >
        <span>
          <IconButton
            onClick={() => handleOpenHistory(options[0].value)}
            disabled={derivedDisabled || options[0].disabled}
          >
            <HistoryIcon fontSize="medium" />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <>
      <Tooltip
        enterNextDelay={700}
        enterDelay={700}
        leaveDelay={0}
        title={tooltipText}
        disableInteractive={true}
        placement="top"
      >
        <IconButton
          onClick={handleClick}
          disabled={derivedDisabled}
          sx={{ width: 'auto' }}
        >
          <HistoryIcon fontSize="medium" />
          <ArrowDropDown
            color={derivedDisabled ? 'disabled' : undefined}
            fontSize="small"
          />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            disabled={option.disabled}
            onClick={() => handleOpenHistory(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}
