import { useQueries } from 'react-query'
import { Maybe } from '@fintastic/shared/util/types'
import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import {
  ChartAggRequestDimension,
  loadChartData,
} from '../api/metrics-chart-api'
import { MetricChartDimensions } from '@fintastic/web/feature/charts'
import { useMemo, useRef } from 'react'
import { compact } from 'lodash'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { LoadChartDataRequestError } from '../types'

export const getChartRequestDimensions = (
  dimensions?: Maybe<MetricChartDimensions>,
): ChartAggRequestDimension[] | undefined =>
  dimensions && dimensions.length > 0
    ? dimensions
        .filter((d) => d.type !== 'Version') // @todo: aggregation by version on backend
        .map((d) => ({
          dimension_uid: d.id,
          aggregate: Boolean(d.aggregate),
          time_dimension: d.type === 'Time',
        }))
    : []

export function useLoadMetricChartData(
  versionIds: string[],
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: Maybe<MetricChartDimensions>,
  enabled = true,
) {
  // remap to ChartAggDimension
  const chartRequestDimensions = getChartRequestDimensions(dimensions)

  const sortedVersions = useMemo(() => compact(versionIds).sort(), [versionIds])

  const queries = useQueries(
    sortedVersions.map((versionId) => ({
      queryKey: metricsAndListsCacheKeys.metricChart(
        versionId,
        metricId,
        periodSelection,
        chartRequestDimensions,
      ),
      queryFn: () =>
        loadChartData(
          versionId,
          metricId,
          periodSelection,
          chartRequestDimensions,
        ),
      enabled,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1,
    })),
  )

  const isFetching = !!queries.find((i) => i.isFetching)
  const isLoading = !!queries.find((i) => i.isLoading)
  const errors = queries.filter((i) => i.isError)
  
  const memoKey = queries
    .map(
      (query, queryIndex) => `${versionIds[queryIndex]}-${query.dataUpdatedAt}`,
    )
    .join(';')

  const queriesRef = useRef(queries)
  queriesRef.current = queries

  return useMemo<{
    isFetching: boolean
    isLoading: boolean
    data: MetricChartWithVersionId[]
    memoKey: string
    isError: boolean
    errors: LoadChartDataRequestError[]
  }>(
    () => ({
      isFetching,
      isLoading,
      data: queriesRef.current
        .filter((i) => !!i.data)
        .map(({ data }) => ({
          ...data,
        })),
      memoKey,
      isError: errors.length > 0,
      errors: errors.map((i) => i.error as LoadChartDataRequestError),
    }),
    [isFetching, isLoading, memoKey, errors, sortedVersions],
  )
}

export type MetricChartWithVersionId = {
  versionId: string
  metric?: Metric
}
