import React, {
  ForwardedRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import type {
  BaseExportParams,
  ColDef,
  ColGroupDef,
  ColumnApi,
  ColumnRowGroupChangedEvent,
  GetLocaleTextParams,
  GetRowIdFunc,
  ICellRendererParams,
  ProcessDataFromClipboardParams,
} from 'ag-grid-community'
import { GridOptions } from 'ag-grid-community'
import {
  addButtonsIdentifiers,
  agGridComponentsMap,
  selectorColumnDefinition,
  useCopyFormattedValue,
} from '@fintastic/shared/ui/ag-grid'
import type {
  BaseGridRow,
  BuildBaseGridColumnDefinitionParams,
  BuildBaseGridColumnGroupDefinitionParams,
} from '../../types'
import { BaseGridSharedProps } from '../../types'
import {
  combineExternalFilters,
  cypressHack,
  extendEditableColDefProp,
  ExternalFilter,
  useAgGridDisableContextMenuWhileEditing,
} from '@fintastic/shared/util/ag-grid'
import { compact, noop } from 'lodash'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import {
  CellEditingStartedEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  ModelUpdatedEvent,
  PasteStartEvent,
  RowDataUpdatedEvent,
  RowDragEvent,
} from 'ag-grid-community/dist/lib/events'
import {
  AgFintasticThemeOptions,
  AgGridThemeFintasticWrapper,
  getFintasticAgIconsRecord,
} from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import {
  buildBaseGridColumnDefinition,
  buildGroupOfColumns,
  tryDateTitle,
} from '../../utils/buildBaseGridColumnDefinition'
import {
  BaseGridContext,
  baseGridContext,
} from '../../context/base-grid-context'
import { wrapCellValueChangedCallback } from '../../utils/callbacks'
import { Panel } from '@fintastic/shared/ui/panel-framework'
import { Maybe } from '@fintastic/shared/util/types'
import { CellRange } from 'ag-grid-community/dist/lib/interfaces/IRangeService'
import { CheckboxSelectionCallbackParams } from 'ag-grid-community/dist/lib/entities/colDef'
import clsx from 'clsx'
import {
  SideBarDef,
  ToolPanelDef,
} from 'ag-grid-community/dist/lib/entities/sideBar'
import {
  MASKED_VALUE,
  stringifyBlankOrMaskedValue,
} from '@fintastic/web/util/blanks-and-masked'
import { buildSidebarDefinition } from './features/sidebar/buildSidebarDefinition'
import { idLooksLikeDimensionValue } from '@fintastic/web/util/dimensions'
import { GetContextMenuItemsParams } from 'ag-grid-community/dist/lib/entities/iCallbackParams'
import {
  csvIcon,
  ExportFormat,
  exportGrid,
  ExportMode,
  xlsIcon,
} from '@fintastic/shared/util/ag-grid-export'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { actionIcons } from './assets/base-grid-icons'
import { GridHighlightApi } from './features/highlight/types'
import { highlightAGGridCells } from './features/highlight'
import {
  GridCoordinatesColumns,
  GridCoordinatesRows,
} from '@fintastic/shared/ui/app-layout-framework'

const compactThemeOptions: AgFintasticThemeOptions = {
  variant: 'compact',
}

const defaultThemeOptions: AgFintasticThemeOptions = {
  variant: 'default',
}

const defaultGridStyles = {
  flex: 1,
  height: '400px', // this is a "default", the client needs to decide.
  width: '100%',
}

const initialDefaultColDef: ColDef<any> = {
  filter: true,
  sortable: true,
  resizable: true,
}

const TOOLTIP_SHOW_DELAY = 500

export type MasterDetailProps<TData extends BaseGridRow> = Pick<
  AgGridReactProps<TData>,
  | 'detailCellRenderer'
  | 'detailRowHeight'
  | 'detailRowAutoHeight'
  | 'detailCellRendererParams'
>

export type BaseGridProps<TData extends BaseGridRow> =
  BaseGridSharedProps<TData> & {
    columns?: (
      | BuildBaseGridColumnDefinitionParams
      | BuildBaseGridColumnGroupDefinitionParams
    )[]
    enableTitleDateAutoFormatting?: boolean
    fallbackGroupValueFormatter?: (value: unknown) => Maybe<string>
    agColumnDefs?: ColDef<TData>[]
    masterDetailProps?: MasterDetailProps<TData>
    rootDataAttributes?: Record<string, string>
    errorContent?: Maybe<React.ReactNode>
    apiRef?: React.MutableRefObject<Maybe<GridApi<TData>> | undefined>
    agContext?: Record<string, any>
    defaultColDef?: ColDef<TData>
    sideBarEnabled?: boolean
    enableColumnsSelectionSidebar?: boolean
    processDataFromClipboard?: (
      params: ProcessDataFromClipboardParams<TData>,
    ) => Maybe<string[][]>
    customToolPanels?: ToolPanelDef[]
    themeWrapperClassNames?: Record<string, boolean>
    suppressClipboardPaste?: boolean
    suppressFiltersToolPanel?: boolean
    suppressLoadingOverlay?: boolean
    externalFilters?: ExternalFilter<TData>[]
    overlayNoRowsTemplate?: GridOptions<TData>['overlayNoRowsTemplate']
    noRowsOverlayComponent?: GridOptions<TData>['noRowsOverlayComponent']
    noRowsOverlayComponentParams?: GridOptions<TData>['noRowsOverlayComponentParams']
    agIcons?: Record<string, string>
    onRowDragEnd?: (event: RowDragEvent<TData>) => void
    allowRowDnD?: boolean
    fitToSizeOnDisplay?: boolean
    components?: any
    forceUniqueRowIds?: boolean
  }

export const BaseGrid = <TData extends BaseGridRow>(
  props: BaseGridProps<TData> & {
    highlightApiRef?: ForwardedRef<GridHighlightApi>
  },
): JSX.Element => {
  const {
    isLoading = false,
    onRowDragEnd,
    allowRowDnD,
    rowData,
    columns,
    context,
    allowGrouping,
    agColumnDefs,
    compactLayout = false,
    apiRef,
    gridRef,
    editProps,
    checkboxSelection,
    headerCheckboxSelection = true,
    getContextMenuItems,
    components,
    suppressLoadingOverlay,
    suppressMovableColumns = false,
    initialGrouping = false,
    eventHandlers,
    onCollapseStateChanged,
    collapsedFromOutside,
    themeWrapperSx,
    children,
    topbar,
    bottomBar,
    overlay,
    sx,
    embeddedView = false,
    hideContainerShadows = false,
    setGridSizeCallback,
    pinnedBottomRowData,
    isPinnedBottomRowEditable = false,
    // @todo after 01-01-2024 - check if we use it anywhere and if not - delete it
    enableTitleDateAutoFormatting = true,
    fallbackGroupValueFormatter,
    rootDataAttributes,
    errorContent = null,
    agContext,
    defaultColDef = initialDefaultColDef,
    sideBarEnabled = true,
    processDataFromClipboard,
    customToolPanels,
    enableColumnsSelectionSidebar = false,
    themeWrapperClassNames,
    suppressClipboardPaste,
    externalFilters,
    overlayNoRowsTemplate,
    noRowsOverlayComponent,
    noRowsOverlayComponentParams,
    agIcons,
    fitToSizeOnDisplay,
    suppressFiltersToolPanel,
    highlightApiRef,
    forceUniqueRowIds = false,
  } = props
  const rootPanelRef = useRef<Maybe<HTMLDivElement>>(null)

  const groupingState = useState(initialGrouping && !!allowGrouping)
  const [isGrouped, setIsGrouped] = groupingState

  // React on initial grouping change
  const isGroupedRef = React.useRef(isGrouped)
  isGroupedRef.current = isGrouped
  useEffect(() => {
    if (isGroupedRef.current !== initialGrouping) {
      setIsGrouped(initialGrouping)
    }
  }, [initialGrouping, setIsGrouped])

  const columnDefinitions: (ColDef<TData> | ColGroupDef<TData>)[] =
    useMemo(() => {
      const columnBuilder = buildBaseGridColumnDefinition(context)
      let agGridColumns = agColumnDefs
        ? agColumnDefs
        : (columns || []).map((columnOrGroup) => {
            if ('children' in columnOrGroup) {
              return buildGroupOfColumns(columnBuilder)(columnOrGroup)
            }
            return columnBuilder(columnOrGroup)
          })

      agGridColumns = compact([
        checkboxSelection
          ? {
              ...selectorColumnDefinition,
              checkboxSelection: checkboxSelection
                ? (params: CheckboxSelectionCallbackParams<TData>) =>
                    !params.node.group &&
                    (typeof checkboxSelection === 'function'
                      ? checkboxSelection(params)
                      : checkboxSelection)
                : undefined,
              menuTabs: ['generalMenuTab', 'filterMenuTab'],
              headerCheckboxSelection: !!headerCheckboxSelection,
            }
          : null,
        ...agGridColumns,
      ])

      return (
        (agGridColumns || [])
          // try to parse headerName
          .map((colDef) => ({
            ...colDef,
            headerName: enableTitleDateAutoFormatting
              ? tryDateTitle(colDef.headerName || '')
              : titleFormatter(colDef.headerName),
          }))
          .map((colDef) => {
            const newColDef =
              'children' in colDef
                ? {
                    ...colDef,
                    children: colDef.children.map((c) => ({ ...c })),
                  }
                : { ...colDef }

            const modifyUsualColDef = (def: ColDef) => {
              /* eslint-disable no-param-reassign */
              // change col defs for grouping view mode
              if (isGrouped && def.enableRowGroup) {
                def.hide = true
              }
              def.rowGroup = isGrouped && def.enableRowGroup
              def.initialRowGroup = isGrouped && def.enableRowGroup

              // provide an "editable" callback that wraps an "editable" field from col def
              def.editable = extendEditableColDefProp<TData>(
                def.editable,
                (params) => {
                  if (
                    params.data?.[params.column.getColId() || ''] ===
                    MASKED_VALUE
                  ) {
                    return false
                  }
                  const supposedToBeEditable = isGrouped
                    ? !params.node.group
                    : true
                  if (params.node.rowPinned === 'bottom') {
                    return supposedToBeEditable && isPinnedBottomRowEditable
                  }
                  return supposedToBeEditable
                },
              )

              const oldCellClassRules = def.cellClassRules
              def.cellClassRules = {
                ...oldCellClassRules,
                'disable-overflow': (params) =>
                  params.colDef.cellRenderer === 'defaultCellRenderer',
              }
              /* eslint-enable no-param-reassign */
            }

            // modify col defs for usual columns
            if (!('children' in newColDef)) {
              modifyUsualColDef(newColDef)
            } else {
              newColDef.children.forEach((colDef) => modifyUsualColDef(colDef))
            }

            return newColDef
          })
      )
    }, [
      context,
      agColumnDefs,
      columns,
      checkboxSelection,
      headerCheckboxSelection,
      enableTitleDateAutoFormatting,
      isGrouped,
      isPinnedBottomRowEditable,
    ])

  const cellRangesAtEditingStartedRef = useRef<Maybe<CellRange[]>>(null)
  const updateEditStartCellRanges = useCallback(
    (event: CellEditingStartedEvent<TData> | PasteStartEvent<TData>) => {
      cellRangesAtEditingStartedRef.current =
        event.api.getCellRanges()?.map<CellRange>((range) => ({
          // we need to clone the range with all array-like and plain object-like fields
          // because at moment of getting the data the original reference can point on an updated range
          // but we need the range that was selected exactly when editing started
          columns: range.columns.map((c) => c),
          startRow: range.startRow ? { ...range.startRow } : undefined,
          endRow: range.endRow ? { ...range.endRow } : undefined,
          startColumn: range.startColumn,
        })) || null
    },
    [],
  )

  const rowIdField = editProps?.rowIdField
  const onCellValueChangedCallback = useMemo(
    () =>
      wrapCellValueChangedCallback(
        editProps?.onCellValueChanged || noop,
        () => ({
          cellRangesAtEditingStarted: cellRangesAtEditingStartedRef.current,
        }),
      ),
    [editProps?.onCellValueChanged],
  )

  const showToggleGrouping = useMemo(
    () =>
      allowGrouping &&
      columnDefinitions
        .flatMap((col) => (col as ColGroupDef)?.children || [col])
        .filter((col) => (col as ColDef<TData>).enableRowGroup).length > 0,
    [allowGrouping, columnDefinitions],
  )

  const sideBar = useMemo<SideBarDef>(
    () =>
      buildSidebarDefinition({
        customToolPanels,
        showToggleGrouping,
        showColumnsSelection: enableColumnsSelectionSidebar,
        suppressFiltersToolPanel,
      }),
    [
      customToolPanels,
      enableColumnsSelectionSidebar,
      showToggleGrouping,
      suppressFiltersToolPanel,
    ],
  )

  const externalFilter = useMemo(() => {
    if (!externalFilters?.length) {
      return null
    }
    return combineExternalFilters<TData>(externalFilters)
  }, [externalFilters])

  const [gridApi, setGridApi] = useState<Maybe<GridApi>>(null)
  const [columnApi, setColumnApi] = useState<Maybe<ColumnApi>>(null)
  const onGridReady = useCallback(
    (event: GridReadyEvent<TData>) => {
      if (rootPanelRef.current) {
        addButtonsIdentifiers(rootPanelRef.current)
      }
      setGridApi(event.api)
      setColumnApi(event.columnApi)
      if (apiRef) {
        apiRef.current = event.api
      }
      if (eventHandlers?.onGridReady) {
        eventHandlers.onGridReady(event)
      }
    },
    [apiRef, eventHandlers],
  )

  useEffect(() => {
    if (gridApi && setGridSizeCallback) {
      const themeSizes = gridApi.getSizesForCurrentTheme()
      if (!themeSizes) {
        return
      }
      setGridSizeCallback({
        rows: rowData?.length ?? 0,
        rowHeight: themeSizes.rowHeight,
        headerHeight: themeSizes.headerHeight || 0,
      })
    }
  }, [gridApi, rowData?.length, setGridSizeCallback])

  const columnDefinitionsRef = useRef(columnDefinitions)
  columnDefinitionsRef.current = columnDefinitions
  useEffect(() => {
    if (!gridApi) {
      return
    }
    // for some reason when there are more then 1 version passed,
    // the switch between grouped and non-grouped view doesn't work
    // so we need this hack to trigger an update
    gridApi.setColumnDefs(columnDefinitionsRef.current)
  }, [isGrouped, gridApi])

  const getRowDataId = useCallback(
    (row: BaseGridRow) => String(row[rowIdField as keyof BaseGridRow]),
    [rowIdField],
  )

  const getRowIdCallback: GetRowIdFunc<TData> = useCallback(
    (params) => getRowDataId(params.data),
    [getRowDataId],
  )

  // for the case when data has not unique id values turn off some features
  // without unique ids aggrid can raise exceptions for some features like sorting
  const uniqueRowIdValues = useMemo(() => {
    if (forceUniqueRowIds) {
      return true
    }

    if (!rowIdField || !rowData) {
      return false
    }
    const uniqueIDs = new Set(rowData.map((row) => getRowDataId(row)))
    const unique = [...uniqueIDs].length === rowData.length
    if (!unique) {
      console.error(
        `in provided data id field ${String(rowIdField)} is not unique`,
      )
    }
    return unique
  }, [getRowDataId, rowData, rowIdField, forceUniqueRowIds])

  const contextMenuDisableWhileEditingProps =
    useAgGridDisableContextMenuWhileEditing()

  const icons = useMemo<Record<string, string>>(
    () => ({
      ...actionIcons,
      ...getFintasticAgIconsRecord(),
      ...agIcons,
    }),
    [agIcons],
  )

  const cypressHackCallback = useCallback(() => {
    cypressHack(gridApi)
  }, [gridApi])

  const handleRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<TData>) => {
      cypressHackCallback()
      eventHandlers?.onRowDataUpdated?.(event)
    },
    [cypressHackCallback, eventHandlers],
  )

  const handleOnFirstDataRendered = useCallback(
    (event: FirstDataRenderedEvent<TData>) => {
      cypressHackCallback()
      eventHandlers?.onFirstDataRendered?.(event)
      if (fitToSizeOnDisplay) {
        gridApi?.sizeColumnsToFit()
      }
      if (rootPanelRef.current) {
        addButtonsIdentifiers(rootPanelRef.current)
      }
    },
    [fitToSizeOnDisplay, cypressHackCallback, eventHandlers, gridApi],
  )

  const handleModelUpdate = useCallback(
    (event: ModelUpdatedEvent<TData>) => {
      cypressHackCallback()
      eventHandlers?.onModelUpdated?.(event)
      if (rootPanelRef.current) {
        addButtonsIdentifiers(rootPanelRef.current)
      }
      if (!checkboxSelection || !isGrouped || !columnApi) {
        return
      }
      columnApi.moveColumn(selectorColumnDefinition.colId as string, 0)
    },
    [
      checkboxSelection,
      columnApi,
      cypressHackCallback,
      eventHandlers,
      isGrouped,
    ],
  )

  const [columnFilterActive, setColumnFilterActive] = useState(false)

  const handleFilterChanged = useCallback(
    (event: FilterChangedEvent<TData>) => {
      eventHandlers?.onFilterChanged?.(event)
      setColumnFilterActive(event.api.isColumnFilterPresent())
    },
    [eventHandlers],
  )

  const handleColumnRowGroupChanged = useCallback(
    (event: ColumnRowGroupChangedEvent<TData>) => {
      eventHandlers?.onColumnRowGroupChanged?.(event)
      event.api.recomputeAggregates()
    },
    [eventHandlers],
  )

  const resetSelected = useCallback(() => {
    if (!gridApi) {
      return
    }
    gridApi.deselectAll()
  }, [gridApi])

  // used only to re-render grid on reset button
  const refreshState = useState(1)

  const [refreshKey] = refreshState

  useEffect(() => {
    resetSelected()
  }, [refreshKey, resetSelected])

  useEffect(() => {
    if (!checkboxSelection || !isGrouped || !columnApi) {
      return
    }
    columnApi.moveColumn(selectorColumnDefinition.colId as string, 0)
  }, [checkboxSelection, columnApi, isGrouped])

  const show = !collapsedFromOutside

  const contextValue = useMemo<BaseGridContext>(
    () => ({
      gridApi,
      collapseState: [
        Boolean(collapsedFromOutside),
        onCollapseStateChanged ?? null,
      ],
      groupingState,
      refreshState,
      embeddedView,
    }),
    [
      gridApi,
      collapsedFromOutside,
      onCollapseStateChanged,
      groupingState,
      refreshState,
      embeddedView,
    ],
  )

  const autoGroupColumnDef = useMemo<ColDef<TData>>(
    () => ({
      minWidth: 385,
      pinned: 'left',
      sort: 'asc',
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: (params: ICellRendererParams) => {
          let displayValue = params.valueFormatted
          if (
            (displayValue === undefined || displayValue === null) &&
            fallbackGroupValueFormatter
          ) {
            displayValue = fallbackGroupValueFormatter(params.value)
          }
          return (
            <span>
              {displayValue || params.value || <i>empty {params.node.field}</i>}
              <span className="grouped-record-count">
                {params.node.allLeafChildren &&
                  `(${params.node.allLeafChildren.length} records)`}
              </span>
            </span>
          )
        },
      },
    }),
    [fallbackGroupValueFormatter],
  )

  const themeOptions = useMemo(
    () => ({
      ...(compactLayout ? compactThemeOptions : defaultThemeOptions),
      inscribedShape: !embeddedView,
      hideColumnsSelectionSidebar: !enableColumnsSelectionSidebar,
    }),
    [compactLayout, embeddedView, enableColumnsSelectionSidebar],
  )

  const masterDetailProps = useMemo(
    () =>
      props?.masterDetailProps
        ? { ...props.masterDetailProps, masterDetail: true }
        : undefined,
    [props?.masterDetailProps],
  )

  const handleCopyFormattedValue = useCopyFormattedValue()

  const handleGetDefaultContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => {
      const exportFn = (format: ExportFormat, mode: ExportMode) => {
        try {
          exportGrid({
            ...params,
            format,
            mode,
            fallbackGroupValueFormatter,
          })
        } catch (e) {
          toast.error(e?.toString() || 'Unknown error')
        }
      }

      return [
        'copy',
        'separator',
        {
          name: 'Download as MS Excel',
          icon: xlsIcon,
          action: () => exportFn('xls', 'current'),
        },
        {
          name: 'Download as CSV',
          icon: csvIcon,
          action: () => exportFn('csv', 'current'),
        },
      ]
    },
    [fallbackGroupValueFormatter],
  )

  const agGridComponents = useMemo(
    () => ({ ...agGridComponentsMap, ...components }),
    [components],
  )

  const exportParams = useMemo<BaseExportParams>(
    () => ({
      processCellCallback(params) {
        // Format dimension values
        if (params.value && idLooksLikeDimensionValue(params.value)) {
          const formatter =
            params.column.getUserProvidedColDef()?.valueFormatter

          if (typeof formatter === 'function') {
            return formatter({
              ...params,
              data: params.node?.data,
              colDef: params.column.getColDef(),
              node: params.node || null,
            })
          }
        }

        return stringifyBlankOrMaskedValue(params.value)
      },
    }),
    [],
  )

  useImperativeHandle(
    highlightApiRef,
    (): GridHighlightApi => ({
      flashCells: (
        rows: Maybe<GridCoordinatesRows>,
        cols: Maybe<GridCoordinatesColumns>,
      ): boolean =>
        highlightAGGridCells({
          gridApi: gridApi,
          columnApi: columnApi,
          rows: rows,
          cols: cols,
        }),
    }),
  )

  if (!columnDefinitions) {
    console.error('Fintastic Grid must accept either agColumnDefs or columns')
    return <></>
  }
  return (
    <baseGridContext.Provider value={contextValue}>
      <Panel
        ref={rootPanelRef}
        sx={sx}
        hideShadow={hideContainerShadows || embeddedView}
        hideBackground={embeddedView}
        hideRoundedBorders={embeddedView}
        fullHeight={true}
        data-testid="base-grid-root"
        topbar={topbar}
        bottomBar={bottomBar}
        overlay={overlay}
        isLoading={isLoading}
        rootDataAttributes={rootDataAttributes}
        errorContent={errorContent}
      >
        {children}
        {show && !isLoading && (
          <AgGridThemeFintasticWrapper
            sx={{
              ...defaultGridStyles,
              height: '100%',
              ...themeWrapperSx,
            }}
            className={clsx({
              'column-filter-is-applied': columnFilterActive,
              ...themeWrapperClassNames,
            })}
            themeOptions={themeOptions}
          >
            {({ defaultGridProps }) => (
              <AgGridReact
                key={refreshKey}
                {...defaultGridProps}
                {...contextMenuDisableWhileEditingProps}
                {...eventHandlers}
                {...masterDetailProps}
                defaultColDef={defaultColDef}
                ref={gridRef}
                context={agContext}
                rowData={rowData}
                rowDragManaged={allowRowDnD}
                onRowDragEnd={onRowDragEnd}
                sideBar={sideBarEnabled && sideBar}
                onFirstDataRendered={handleOnFirstDataRendered}
                getRowId={uniqueRowIdValues ? getRowIdCallback : undefined}
                onCellValueChanged={onCellValueChangedCallback}
                onGridReady={onGridReady}
                onRowDataUpdated={handleRowDataUpdated}
                onModelUpdated={handleModelUpdate}
                onCellEditingStarted={updateEditStartCellRanges}
                onColumnRowGroupChanged={handleColumnRowGroupChanged}
                onFilterChanged={handleFilterChanged}
                components={agGridComponents}
                rowSelection="multiple"
                suppressMovableColumns={suppressMovableColumns}
                singleClickEdit
                suppressRowClickSelection
                showOpenedGroup
                groupRemoveLowestSingleChildren
                enterMovesDownAfterEdit
                animateRows
                suppressAggFuncInHeader
                tooltipShowDelay={TOOLTIP_SHOW_DELAY}
                icons={icons}
                getContextMenuItems={
                  getContextMenuItems || handleGetDefaultContextMenuItems
                }
                getLocaleText={(params: GetLocaleTextParams) => {
                  if (params.key === 'groups') {
                    return 'Group by'
                  }
                  return params.defaultValue
                }}
                autoGroupColumnDef={autoGroupColumnDef}
                groupDisplayType="singleColumn"
                suppressFieldDotNotation={true}
                pinnedBottomRowData={pinnedBottomRowData}
                processCellForClipboard={handleCopyFormattedValue}
                processDataFromClipboard={processDataFromClipboard}
                suppressClipboardPaste={suppressClipboardPaste}
                columnDefs={columnDefinitions}
                onCellEditingStopped={editProps?.onCellEditingStopped}
                stopEditingWhenCellsLoseFocus={
                  editProps?.stopEditingWhenCellsLoseFocus
                }
                defaultExcelExportParams={exportParams}
                defaultCsvExportParams={exportParams}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
                noRowsOverlayComponent={noRowsOverlayComponent}
                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                isExternalFilterPresent={
                  externalFilter?.isExternalFilterPresent
                }
                doesExternalFilterPass={externalFilter?.doesExternalFilterPass}
                suppressLoadingOverlay={suppressLoadingOverlay}
              />
            )}
          </AgGridThemeFintasticWrapper>
        )}
      </Panel>
    </baseGridContext.Provider>
  )
}
