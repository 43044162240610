import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'
import { styleKit } from '@fintastic/shared/ui/mui-theme'

export const StyledChartTabsRoot = styled(Box)`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
`

export const StyledChartTabContent = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  width: 353px;
  min-width: 353px;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.palette.grey[500]} white;
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;

  filter: drop-shadow(-4px 0 4px rgba(0, 0, 0, 0.05));
  transform-origin: right center;
  animation: showPanel 0.2s ease-in-out;

  @keyframes showPanel {
    0% {
      opacity: 0;
      translate: 20px 0;
    }
    100% {
      opacity: 1;
      translate: 0 0;
    }
  }
`

export const StyledChartTabs = styled(Box)`
  height: 100%;
  width: 40px;
  min-width: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  align-content: center;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  padding-top: 0;

  svg {
    width: 20px;
  }
`

export const StyledChartTab = styled(
  (
    props: {
      active?: boolean
      disabled?: boolean
      highlight?: boolean
    } & BoxProps,
  ) => {
    const { active, disabled, highlight, ...boxProps } = props
    return <Box {...boxProps} />
  },
  {
    shouldForwardProp: (propName: PropertyKey) =>
      propName !== 'active' &&
      propName !== 'disabled' &&
      propName !== 'highlight',
  },
)`
  width: 40px;
  height: 47px;
  display: flex;
  place-content: center;

  background: ${({ theme, highlight }) =>
    highlight ? theme.palette.primary.light : 'none'};

  margin: ${({ theme }) => theme.spacing(1)} 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.text.disabled : theme.palette.text.primary};
  position: relative;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  svg {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.palette.text.disabled : 'inherit'};
  }

  box-sizing: border-box;
  border-left: 2px solid
    ${({ active, theme }) =>
      active
        ? styleKit.colors.orange.medium
        : 'transparent'}; // theme.palette.primary.main

  &:hover {
    button {
      background: none;
      border-color: transparent;

      svg {
        fill: ${styleKit.colors.orange.medium};
      }
    }
  }

  button {
    width: 40px;
    height: 100%;
    border: none;
    position: absolute;
    top: 0;
    left: -2px;
    display: flex;
    place-content: center;

    &:hover,
    &:focus {
      background: none;
      border-color: transparent;
    }
  }
`
