import { ChartType } from './types'
import { ReactNode } from 'react'
import { ChartTypeBarsIcon } from './components/icons/ChartTypeBarsIcon' // no shortcut!
import { ChartTypeLinesIcon } from './components/icons/ChartTypeLinesIcon'
// import { ChartTypeStackedBarsIcon } from './components/icons/ChartTypeStackedBarsIcon'
// import { ChartTypeWaterfallIcon } from './components/icons/ChartTypeWaterfallIcon'

export const ChartIcons: Record<ChartType, ReactNode> = {
  bars: <ChartTypeBarsIcon />,
  lines: <ChartTypeLinesIcon />,
  // stackedBars: <ChartTypeStackedBarsIcon />,
  // waterfall: <ChartTypeWaterfallIcon />,
}
