import { Box, styled } from '@mui/material'

export const StyledChartLocalSettings = styled(Box)`
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  position: relative;
`

export const StyledLoadingSettings = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  place-content: center;
  width: 100%;
  height: 100%;
`

export const StyledChartLocalButtons = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1)} 0;
  margin: ${({ theme }) => theme.spacing(2)} 0;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`
