import {
  FailedDataUpdateEventWrapper,
  logEvent,
  SuccessfulDataUpdateEventWrapper,
} from '@fintastic/web/util/metric-data-editing'
import { Maybe } from '@fintastic/shared/util/types'
import { QueryClient } from 'react-query'
import { invalidateReportsCache } from '@fintastic/web/util/generic-report'
import {
  getVersionDependenciesGraph,
  invalidateVersionUserLockerCache,
} from '@fintastic/web/data-access/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { invalidateEverythingForVersion } from './invalidateEverythingForVersion'
import { getAffectedEntities } from './getAffectedEntities'
import { performAffectedEntitiesInvalidation } from './performAffectedEntitiesInvalidation'
import { invalidateV2MetricCache } from '@fintastic/web/data-access/metrics-and-lists'
import { invalidateEntitiesAffectedByList } from './invalidation-utils'
import {
  GENERIC_CALC_FAILED_MESSAGE,
  GENERIC_CALC_SUCCEED_MESSAGE,
} from './notification-text'
import {
  ParsedColumnId,
  idLooksLikeColumn,
} from '@fintastic/web/util/metrics-and-lists'
import { invalidateHistoryLog } from '@fintastic/web/data-access/history'

type Context = {
  userEmail: Maybe<string>
}

type Handler<
  T extends SuccessfulDataUpdateEventWrapper | FailedDataUpdateEventWrapper =
    | SuccessfulDataUpdateEventWrapper
    | FailedDataUpdateEventWrapper,
> = (event: T, queryClient: QueryClient, context: Context) => Promise<void>

export const handleCellDataUpdateEvent: Handler = async (
  event,
  queryClient,
  context,
) => {
  logEvent(event)
  showToast(event, context.userEmail)

  if (event instanceof FailedDataUpdateEventWrapper) {
    return invalidateEverythingForVersion(event.versionId, queryClient)
  }

  const parallelPromises: Promise<unknown>[] = [
    invalidateReportsCache(queryClient, [event.versionId]),
    invalidateVersionUserLockerCache(queryClient, [event.versionId]),
    tryToReloadAffectedEntities(event, queryClient, context),
    invalidateListCacheOnAddRows(event, queryClient, context),
    invalidateListCacheOnChangeRows(event, queryClient, context),
    invalidateHistoryLogCache(event, queryClient, context),
  ]

  await Promise.all(parallelPromises)
}

const tryToReloadAffectedEntities: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient, context) => {
  if (
    event.updateData.action !== 'edit_list_column_data' &&
    event.updateData.action !== 'edit_metric_data'
  ) {
    return
  }

  const dependenciesGraph = await getVersionDependenciesGraph(
    queryClient,
    event.versionId,
  )
  if (!dependenciesGraph) {
    await invalidateEverythingForVersion(event.versionId, queryClient)
    return
  }

  const entitiesToReload = getAffectedEntities(
    event.updateData.user_modified_entities,
    dependenciesGraph,
    !event.successful,
  )

  await performAffectedEntitiesInvalidation(
    queryClient,
    event.versionId,
    entitiesToReload,
  )
}

const invalidateListCacheOnChangeRows: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient, context) => {
  if (event.updateData.action !== 'edit_list_column_data') {
    return
  }

  await Promise.all(
    event.updateData.user_modified_entities.map(async (entityId) => {
      if (!idLooksLikeColumn(entityId)) {
        return
      }

      const parsedColumnId = ParsedColumnId.fromString(entityId)

      if (!parsedColumnId) {
        return
      }

      return invalidateV2MetricCache.invalidateListColumn(
        queryClient,
        event.versionId,
        parsedColumnId?.listId,
        entityId,
      )
    }),
  )
}

const invalidateListCacheOnAddRows: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient, context) => {
  if (
    event.updateData.action !== 'add_list_rows' &&
    event.updateData.action !== 'add_complete_list_rows'
  ) {
    return
  }

  const promises: Promise<void>[] = []

  promises.push(
    invalidateV2MetricCache.invalidateList(
      queryClient,
      event.versionId,
      event.updateData.list_id,
    ),
  )

  promises.push(
    invalidateEntitiesAffectedByList(
      queryClient,
      event.versionId,
      event.updateData.list_id,
      [],
    ),
  )

  await Promise.all(promises)
}

const invalidateHistoryLogCache: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient) => {
  const dataUpdate = event.updateData

  // because BE writes data to the log not immediately
  await new Promise((resolve) => setTimeout(resolve, 1000 * 3))

  const promises: Promise<void>[] = []

  if (
    dataUpdate.action === 'edit_metric_data' ||
    dataUpdate.action === 'edit_list_column_data'
  ) {
    promises.push(
      invalidateHistoryLog(queryClient, {
        level: 'version',
        versionId: [event.versionId],
      }),
    )

    promises.push(
      invalidateHistoryLog(queryClient, {
        level: 'entity',
        versionId: [event.versionId],
        entityId: dataUpdate.user_modified_entities.reduce<string[]>(
          (ids, id) => {
            const columnId = ParsedColumnId.fromString(id)
            ids.push(columnId?.listId || id)
            return ids
          },
          [],
        ),
      }),
    )
  }

  if (
    dataUpdate.action === 'add_list_rows' ||
    dataUpdate.action === 'add_complete_list_rows'
  ) {
    promises.push(
      invalidateHistoryLog(queryClient, {
        level: 'version',
        versionId: [event.versionId],
      }),
    )

    promises.push(
      invalidateHistoryLog(queryClient, {
        level: 'entity',
        versionId: [event.versionId],
        entityId: [dataUpdate.list_id],
      }),
    )
  }

  await Promise.all(promises)
}

const showToast = (
  event: SuccessfulDataUpdateEventWrapper | FailedDataUpdateEventWrapper,
  userEmail: Maybe<string>,
) => {
  if (!userEmail || event.userEmail !== userEmail) {
    return
  }

  if (!event.successful) {
    toast.error(GENERIC_CALC_FAILED_MESSAGE)
    console.error(`Data edit failed. Error: ${event.errorText}`, event)
    return
  }

  toast.success(GENERIC_CALC_SUCCEED_MESSAGE)
}
