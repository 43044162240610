import React from 'react'
import { StyledChartParams } from './ChartDebugParams.styled'
import { useIsFintasticUser } from '@fintastic/web/feature/auth'
import { ChartColumnData, ChartRequestParams } from '../../../../../types'
import { Maybe } from '@fintastic/shared/util/types'

export type ChartParamsProps = {
  params: Record<string, unknown>
  request: ChartRequestParams
  data: Maybe<ChartColumnData>
}

export const ChartDebugParams: React.FC<ChartParamsProps> = ({
  params,
  request,
  data,
}) => {
  const isDebugModalEnabled = useIsFintasticUser()

  if (!isDebugModalEnabled) {
    return null
  }

  return (
    <StyledChartParams>
      <table>
        <thead>
          <tr>
            <th>Chart params</th>
            <th>Request</th>
            <th>Raw data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <pre>{JSON.stringify(params, null, 2)}</pre>
            </td>
            <td>
              <pre>{JSON.stringify(request, null, 2)}</pre>
            </td>
            <td>
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </td>
          </tr>
        </tbody>
      </table>
    </StyledChartParams>
  )
}
