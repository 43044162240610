import React from 'react'
import { ChartSettingsIcon } from '../../../../icons/ChartSettingIcon'
import { ChartTabs } from './chart-settings-tabs/ChartTabs'
import { ChartTab } from './chart-settings-tabs/ChartTab'
import { ChartLocalSettingsPanel } from './ChartLocalSettingsPanel/ChartLocalSettingsPanel'
import type { ChartSettings } from '@fintastic/web/feature/charts'

export type MetricChartSettingsPanelProps = {
  isDesignMode?: boolean
  loading: boolean
  widgetId: string
  metricId: string
  versions: string[]
  initialSettings: ChartSettings
  highlightSettings?: boolean
}

export const MetricChartSettingsPanel: React.FC<
  MetricChartSettingsPanelProps
> = ({
  isDesignMode,
  widgetId,
  loading,
  metricId,
  versions,
  highlightSettings,
  initialSettings,
}) => (
  <ChartTabs>
    <ChartTab
      icon={<ChartSettingsIcon />}
      title={'Chart settings'}
      disabled={isDesignMode}
      highlight={highlightSettings}
      id={'settings'}
    >
      <ChartLocalSettingsPanel
        widgetId={widgetId}
        loading={loading}
        metricId={metricId}
        versions={versions}
        initialSettings={initialSettings}
      />
    </ChartTab>
  </ChartTabs>
)
