import React from 'react'
import { MetricChartPanels } from './MetricChartPanels/MetricChartPanels'
import { MetricChartSettingsPanel } from './settings-panel/MetricChartSettingsPanel'
import {
  ChartColumnData,
  ChartDisplayMode,
  ChartDisplaySettings,
  ChartRequestParams,
  ChartSettings,
  defaultChartSettings,
} from '@fintastic/web/feature/charts'
import { StyledChartAndSettingsContainer } from './MetricChartContent.styled'
import { ChartActualParams } from '../../types'

export type MetricChartContentProps = {
  actualParams: ChartActualParams
  data: ChartColumnData
  displayMode: ChartDisplayMode
  hasLocalChanges: boolean
  isCollapsedVert?: boolean
  isDesignMode?: boolean
  loading: boolean
  localDisplaySettings: ChartDisplaySettings
  metricId: string
  referenceDisplaySettings: ChartDisplaySettings
  request: ChartRequestParams
  versions: string[]
  widgetChartSettings?: ChartSettings
  widgetId: string
}

export const MetricChartContent: React.FC<MetricChartContentProps> = ({
  actualParams,
  data,
  displayMode,
  hasLocalChanges,
  isCollapsedVert,
  isDesignMode,
  loading,
  localDisplaySettings,
  metricId,
  referenceDisplaySettings,
  request,
  versions,
  widgetChartSettings,
  widgetId,
}) => {
  if (isCollapsedVert) {
    return null
  }

  return (
    <StyledChartAndSettingsContainer>
      <MetricChartPanels
        data={data}
        displayMode={displayMode}
        loading={loading}
        isDesignMode={isDesignMode}
        displaySettings={
          isDesignMode ? referenceDisplaySettings : localDisplaySettings
        }
        params={actualParams}
        request={request}
      />

      <MetricChartSettingsPanel
        loading={loading}
        isDesignMode={Boolean(isDesignMode)}
        widgetId={widgetId}
        metricId={metricId}
        versions={versions}
        initialSettings={widgetChartSettings || defaultChartSettings}
        highlightSettings={hasLocalChanges}
      />
    </StyledChartAndSettingsContainer>
  )
}
