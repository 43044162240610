import {
  FilterListAPIOperator,
  FilterListAPIOperatorBlankNotBlank,
  FilterListAPIOperatorContains,
  FilterListAPIOperatorDimension,
  FilterListAPIOperatorRange,
  FilterListAPIOperatorSingleValue,
  FilterListAPIPayload,
} from '../api-types'
import { Filter, FilterRange, FilterModel } from '../types'
import { compact, isArray, isNumber } from 'lodash'
import type { Maybe } from '@fintastic/shared/util/types'
import {
  getFilterValueAsSelectedDimensions,
  NO_TIME_SPREAD_FILTER,
} from './filter-values-model'
import { isFilterValueEmpty } from './filter-value-utils'

export const mapFiltersToApiPayload = (
  model: FilterModel,
): FilterListAPIPayload => {
  const { filterModelValues, metadata } = model
  const payload: FilterListAPIPayload = {
    filters: [
      {
        group: 'and',
        filters: compact(
          metadata.filters.flatMap<Maybe<FilterListAPIOperator>>((filter) =>
            Object.entries(filterModelValues[filter.id] ?? {}).map<
              Maybe<FilterListAPIOperator>
            >(([timeKey, filterValue]) => {
              const { operator, value, valid } = filterValue ?? {}

              if (!valid) {
                return null
              }

              const time_dimension_value_uid =
                timeKey === NO_TIME_SPREAD_FILTER ? undefined : timeKey

              if (filter.type === 'dimension' && isArray(value)) {
                if (isFilterValueEmpty(filter, filterValue)) {
                  return null
                }
                return {
                  operator: 'dimension',
                  uid: filter.id,
                  value: getFilterValueAsSelectedDimensions(model, filter),
                  time_dimension_value_uid,
                } as FilterListAPIOperatorDimension
              }

              if (
                filter.type === 'string' &&
                operator === 'contains' &&
                (value as string)?.length
              ) {
                return {
                  operator,
                  uid: filter.id,
                  value: value === ' ' ? ' ' : (value as string).trim(),
                  time_dimension_value_uid,
                } as FilterListAPIOperatorContains
              }

              if (filter.type === 'numeric' || filter.type === 'date') {
                if (operator === 'blank' || operator === 'not_blank') {
                  return {
                    operator,
                    uid: filter.id,
                    time_dimension_value_uid,
                  } as FilterListAPIOperatorBlankNotBlank
                }

                if (
                  (typeof value === 'number' || typeof value === 'string') &&
                  value !== '' &&
                  operator !== 'range'
                ) {
                  return {
                    operator,
                    uid: filter.id,
                    value: prepareAPINumericValue(filter, value),
                    time_dimension_value_uid,
                  } as FilterListAPIOperatorSingleValue
                } else if (isPopulatedValidRange(value)) {
                  return {
                    operator,
                    uid: filter.id,
                    value: [
                      prepareAPINumericValue(filter, value[0]),
                      prepareAPINumericValue(filter, value[1]),
                    ],
                    time_dimension_value_uid,
                  } as FilterListAPIOperatorRange
                }
              }

              return null
            }),
          ),
        ),
      },
    ],
  } as const

  return payload.filters?.some((f) => f.filters?.length) ? payload : {}
}

const isPopulatedValidRange = (range?: unknown): range is FilterRange => {
  if (!isArray(range) || range.length !== 2) {
    return false
  }

  const validFirst = range[0] !== undefined && range[0] !== ''
  const validSecond = range[1] !== undefined && range[1] !== ''

  return validFirst && validSecond
}

const prepareAPINumericValue = (
  filter: Filter,
  value: string | number,
): string | number => {
  if (
    filter.dataType !== 'percentage_integer' &&
    filter.dataType !== 'percentage'
  ) {
    return value
  }

  if (!isNumber(value)) {
    return value
  }

  return value / 100
}
