import {
  DimensionTuple,
  VersionDimension,
} from '@fintastic/web/util/dimensions'
import { MetricDataValue } from '@fintastic/web/util/metrics-and-lists'
import { Version } from '@fintastic/web/util/versions'
import {
  MetricChartDimensions,
  MetricChartDimensionsEssential,
} from '@fintastic/web/feature/charts'
import { titleFormatter } from '@fintastic/shared/util/formatters'

export const extractDimensionEssential = (
  dimensions?: MetricChartDimensions,
): MetricChartDimensionsEssential[] =>
  dimensions
    ? dimensions.map((d) => ({
        id: d.id,
        aggregate: !!d.aggregate,
        time_dimension: d.type === 'Time', // because of already created charts
      }))
    : []

export const getVersionDimensionById = (
  versionDimensions: VersionDimension[],
  id?: string,
) => (id ? versionDimensions.find((vd) => vd.id === id) : undefined)

export type ProcessCallbackParams = {
  versionId: string
  versionsMetadata: Record<string, Version>
  versionDimensions: VersionDimension[]
  rows: Record<string, any>
  isPercentage: boolean
  isSingleVersion: boolean
}

export const makeProcessCallback =
  ({
    versionId,
    versionsMetadata,
    versionDimensions,
    rows,
    isPercentage,
    isSingleVersion,
  }: ProcessCallbackParams) =>
  (dimensionTuples: DimensionTuple[], value: MetricDataValue) => {
    const titleParts: string[] = isSingleVersion
      ? []
      : [versionsMetadata[versionId].name || 'version']

    const tuplesWithoutTime = [...dimensionTuples]
    const timeTuple = tuplesWithoutTime.pop()

    const mayBeTimeDimension = timeTuple
      ? getVersionDimensionById(versionDimensions, timeTuple?.[0])
      : undefined

    const hasTimeTuple = mayBeTimeDimension?.type === 'Time'

    const tuplesToProcess = hasTimeTuple ? tuplesWithoutTime : dimensionTuples

    const xColumnName = hasTimeTuple
      ? mayBeTimeDimension.values[timeTuple?.[1] || ''] || '?'
      : 'Value' // @todo: real names

    let rowKey = '' //versionId // version_dims
    tuplesToProcess.forEach((dt) => {
      const dim = getVersionDimensionById(versionDimensions, dt[0])

      rowKey += dt[0] + '|' + dt[1]

      if (dim) {
        const dimName = (dim as any).values
          ? (dim as any).values[dt[1]]
          : undefined

        if (dimName) {
          // titleParts.push(dim.label + ': ' + dimName)
          titleParts.push(
            //titleFormatter(dim.label) + ': ' +
            titleFormatter(dimName),
          )
        } else {
          titleParts.push(titleFormatter(dim.label))
        }
      }
    })

    const val = isPercentage && typeof value === 'number' ? value * 100 : value

    if (!rows[rowKey]) {
      rows[rowKey] = {
        [xColumnName]: val,
        __label: titleParts.join(',\n'),
      }
    } else {
      rows[rowKey][xColumnName] = val
    }
  }
export type MetricChartDataRecords = Record<string, any>

export type VersionedMetricChartDataRow = {
  versionId: string
  rows: Record<string, any>
}

export const getFirstAvailableVersionFromData = (
  rows: VersionedMetricChartDataRow[],
  versions: string[],
) => {
  const firstVersionData = rows.find((r) => r.versionId === versions[0])?.rows

  if (firstVersionData) {
    return {
      firstVersionData,
      firstVersionId: versions[0],
    }
  }

  // if there no firstVersionData === versions[0] (may be error) - get first available version
  return {
    firstVersionData: rows[0]?.rows || {},
    firstVersionId: rows[0]?.versionId,
  }
}
