import React, { memo, useCallback } from 'react'
import { MetricGridWidgetSettings } from './types'
import {
  WidgetDependenciesWarning,
  WidgetRendererProps,
} from '@fintastic/shared/ui/widgets-framework'
import { UsePeriodSelectionComponentReadyValue } from '@fintastic/web/feature/period-selector'
import type { ColumnColor } from '@fintastic/web/util/metrics-and-lists'
import { MetricGridConnector } from '@fintastic/web/feature/metrics-and-lists'

export type MetricGridWidgetProps =
  WidgetRendererProps<MetricGridWidgetSettings> & {
    periodSelection: UsePeriodSelectionComponentReadyValue
  }

export const MetricGridWidget: React.FC<MetricGridWidgetProps> = memo(
  ({
    widget,
    boardParams,
    onChangeSettings,
    isEnoughSpace,
    periodSelection,
  }) => {
    const handleUpdateColumnColors = useCallback(
      (columnColors: ColumnColor[]) => {
        onChangeSettings(widget.id, {
          ...widget.settings,
          columnColors,
        })
      },
      [onChangeSettings, widget.id, widget.settings],
    )

    if (!isEnoughSpace) {
      return (
        <WidgetDependenciesWarning>Not enough space</WidgetDependenciesWarning>
      )
    }

    return (
      <MetricGridConnector
        metricId={widget.settings.metricId}
        versions={boardParams.versions}
        diffs={boardParams.diffs}
        title={widget.settings.displayLabel}
        enableGridReset={false}
        enableGrouping={true}
        columnColors={
          boardParams.isDesignMode ? [] : widget.settings.columnColors || []
        }
        enableColumnColors
        handleUpdateColumnColors={
          boardParams.isDesignMode ? undefined : handleUpdateColumnColors
        }
        periodSelectorComponent={periodSelection.periodSelectorComponent}
      />
    )
  },
)
