import { List, Metric, MetricWithoutData } from '@fintastic/web/util/metrics-and-lists'

/**
 * @deprecated
 */
type WithFormulaState = {
  // @todo figure out better way to store formulas state in reducer
  formulaInvalid?: boolean
  formulaCalculationError?: string
}

export type CreatableColumnProperties = {
  clientOnlyId?: string
  newColumn?: boolean
}

export type MetricAsCreatableColumn = Metric &
  CreatableColumnProperties &
  WithFormulaState

export type MetricAsCreatableColumnWithoutData = MetricWithoutData &
  CreatableColumnProperties &
  WithFormulaState

export type EditableMetric = Metric & WithFormulaState

export type EditableList = Omit<List, 'metrics'> & {
  metrics: MetricAsCreatableColumn[]
} & WithFormulaState & {
    _initiallyHadBaseTimeDimension?: boolean
  }

export type EditableListWithoutData = Omit<List, 'metrics'> & {
  metrics: MetricAsCreatableColumnWithoutData[]
} & WithFormulaState

export type VersionLabelToggleParams = {
  versionId: string
  entityId: string
  labelId: number
  entityType: 'list' | 'metric'
}

export type VersionDescriptionSaveParams = {
  versionId: string
  entityId: string
  description: string
  entityType: 'list' | 'metric'
}

export type LoadChartDataRequestErrorPayload = {
  requestErrorCode: number
  metricId: string
  versionId: string
  message: string
}

export class LoadChartDataRequestError extends Error {
  public requestErrorCode = 520
  public metricId = ''
  public versionId = ''
  public message = ''

  constructor(
    code: number,
    originalMessage: string,
    versionId: string,
    metricId: string,
  ) {
    super(originalMessage)
    this.name = 'LoadChartDataRequestError'
    this.requestErrorCode = code
    this.message = originalMessage
    this.versionId = versionId
    this.metricId = metricId

    Object.setPrototypeOf(this, LoadChartDataRequestError.prototype)
  }
}
