import { axios } from '@fintastic/web/data-access/service-axios'
import type { Metric } from '@fintastic/web/util/metrics-and-lists'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { mapPeriodSelectionToParams } from './helpers/map-period-selection-to-params'
import Qs from 'qs'
import { endpoints } from './metrics-endpoints'
import { AxiosError } from 'axios'
import { LoadChartDataRequestError } from '../types'

export type ChartAggRequestDimension = {
  dimension_uid: string
  aggregate: boolean
  time_dimension: boolean
}

export function getMetricChart(
  versionId: string,
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: ChartAggRequestDimension[],
  includeData = true,
) {
  return axios.post<Metric>(
    endpoints.metricChart(versionId, metricId),
    {
      dimensions: dimensions,
    },
    {
      params: {
        ...mapPeriodSelectionToParams(periodSelection),
        with_data: includeData,
      },
      paramsSerializer: (p: any) => Qs.stringify(p, { arrayFormat: 'repeat' }),
    },
  )
}

export type LoadChartDataResult = {
  metric: Metric
  versionId: string
}

export async function loadChartData(
  versionId: string,
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: ChartAggRequestDimension[],
): Promise<LoadChartDataResult> {
  try {
    const response = await getMetricChart(
      versionId,
      metricId,
      periodSelection,
      dimensions,
    )

    return {
      metric: response.data,
      versionId: versionId,
    }
  } catch (err) {
    let errorCode = 520 // unknown server error
    let errorMessage = ''

    if (typeof (err as AxiosError).response?.status !== 'undefined') {
      errorCode = Number((err as AxiosError).response?.status)
    }

    if (typeof (err as AxiosError).message !== 'undefined') {
      errorMessage = (err as AxiosError).message
    }

    throw new LoadChartDataRequestError(
      errorCode,
      errorMessage || 'Error on requesting data',
      versionId,
      metricId,
    )
  }
}
