import { HistoryLogAction } from './types'

const actionMapping: Record<
  HistoryLogAction,
  {
    label: string
  }
> = {
  change_value: {
    label: 'Edit data',
  },
  add_line: {
    label: 'Add line',
  },
}

export const resolveHistoryLogActionLabel = (
  action: HistoryLogAction,
): string => actionMapping[action].label

const entityTypeMapping: Record<
  'list' | 'metric',
  {
    label: string
  }
> = {
  list: {
    label: 'List',
  },
  metric: {
    label: 'Metric',
  },
}

export const resolveHistoryLogEntityTypeLabel = (
  entityType: 'list' | 'metric',
): string => entityTypeMapping[entityType].label

const relevantActionsPerEntityType: Record<
  'list' | 'metric',
  HistoryLogAction[]
> = {
  list: ['change_value', 'add_line'],
  metric: ['change_value'],
}

export const resolveRelevantActionsForEntityLevel = (
  entityType: 'list' | 'metric',
): HistoryLogAction[] => relevantActionsPerEntityType[entityType]
