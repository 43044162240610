import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { StyledChart } from './Chart.styled'
import { EChartContainer } from './EChartContainer'
import { ChartColumnData, ChartDisplaySettings } from '../../types'
import { Maybe } from '@fintastic/shared/util/types'
import { encodeForBars, encodeForLines } from './chart-utils'
import { defaultChartTheme } from '@fintastic/web/feature/charts'
import { EChartsOption } from 'echarts'
import { isEqual } from 'lodash'

type ChartProps = {
  isDesignMode?: boolean
  displaySettings: ChartDisplaySettings
  data: Maybe<ChartColumnData>
}

export const Chart: React.FC<ChartProps> = ({
  displaySettings,
  isDesignMode,
  data,
}) => {
  const oldData = useRef<Maybe<ChartColumnData>>(null)
  const oldSettings = useRef<Maybe<ChartDisplaySettings>>(null)

  const oldOptions = useRef<Maybe<EChartsOption>>(null)

  const destroying = useRef(false)

  useEffect(() => {
    destroying.current = false
    return () => {
      destroying.current = true
    }
  }, [])

  const [openAtLeastOnce, setOpenAtLeastOnce] = useState(false)

  useLayoutEffect(() => {
    // first display animation
    setTimeout(() => {
      if (!destroying.current) {
        setOpenAtLeastOnce(true)
      }
    }, 20)
  }, [])

  const option = useMemo(() => {
    if (
      isEqual(oldData.current, data) &&
      isEqual(oldSettings.current, displaySettings) &&
      oldOptions.current
    ) {
      return oldOptions.current
    }

    oldData.current = data
    oldSettings.current = displaySettings

    let result: Maybe<EChartsOption> = null
    if (displaySettings.type === 'lines') {
      result = encodeForLines(data)
    }

    if (displaySettings.type === 'bars') {
      result = encodeForBars(data)
    }
    
    oldOptions.current = { ...result, animation: !isDesignMode }

    return oldOptions.current
  }, [displaySettings, data, isDesignMode])

  return (
    <StyledChart>
      <EChartContainer
        option={openAtLeastOnce ? option : {}}
        theme={displaySettings.theme || defaultChartTheme}
      />
    </StyledChart>
  )
}
