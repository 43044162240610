import { EChartsOption } from 'echarts'

const defaultTextStyle = {
  fontSize: 12,
  fontFamily: 'Ubuntu',
  color: '#263646',
}

const axisColor = '#ccc'
const gridColor = '#eee'

export const ChartStyle: EChartsOption = {
  legend: {
    textStyle: defaultTextStyle,
    icon: 'pin',
    bottom: 10,
    type: 'scroll',
  },

  backgroundColor: '#fff',

  // https://github.com/apache/echarts/issues/14724
  grid: {
    bottom: 120,
  },

  line: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: '2',
    },
    symbolSize: '6',
    symbol: 'emptyCircle',
    smooth: false,
  },

  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: '#ccc',
    },
  },

  xAxis: {
    type: 'category',
    axisTick: {
      alignWithLabel: true,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: axisColor,
      },
    },
    axisLabel: {
      rotate: 90,
      ...defaultTextStyle,
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: [gridColor],
      },
    },
  },

  yAxis: {
    axisTick: {
      alignWithLabel: true,
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: axisColor,
      },
    },
    axisLabel: {
      ...defaultTextStyle,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [gridColor],
      },
    },
  },

  tooltip: {
    trigger: 'item',
    axisPointer: {
      type: 'shadow',
    },
    textStyle: defaultTextStyle,
  },

  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: axisColor,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      ...defaultTextStyle,
    },

    splitLine: {
      show: true,
      lineStyle: {
        color: [gridColor],
      },
    },

    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },

  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: axisColor,
      },
    },

    axisTick: {
      show: true,
      lineStyle: {
        color: '#333',
      },
    },

    axisLabel: {
      show: true,
      ...defaultTextStyle,
    },

    splitLine: {
      show: true,
      lineStyle: {
        color: [gridColor],
      },
    },

    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },

  toolbox: {
    iconStyle: {
      borderColor: '#999999',
    },
    emphasis: {
      iconStyle: {
        borderColor: '#666666',
      },
    },
  },
}
