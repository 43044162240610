import type { Maybe } from '@fintastic/shared/util/types'
import { Filter, FilterValue, FilterModel } from '../types'
import { isFilterValueEmpty } from './filter-value-utils'
import { cloneDeep, compact, isArray, isEmpty, isEqual, keyBy } from 'lodash'
import { DEFAULT_FILTERS_VALUE_MODEL } from '../context/default-filter-model'

export const addValueToModel = (
  model: FilterModel,
  filter: Filter,
  timeDimensionValueId: Maybe<string>,
  value: FilterValue | undefined,
  actualMetadata: FilterModel['metadata'],
): FilterModel => {
  const nextModel = cloneDeep(cleanUpModel(model, actualMetadata))
  nextModel.metadata = cloneDeep(actualMetadata)

  const timeKey = timeDimensionValueId ?? NO_TIME_SPREAD_FILTER

  if (isFilterValueEmpty(filter, value)) {
    if (nextModel.filterModelValues[filter.id]?.[timeKey]) {
      delete nextModel.filterModelValues[filter.id]?.[timeKey]

      if (isEmpty(nextModel.filterModelValues[filter.id])) {
        delete nextModel.filterModelValues[filter.id]
      }
    }

    return nextModel
  }

  if (!nextModel.filterModelValues) {
    nextModel.filterModelValues = {}
  }

  if (!nextModel.filterModelValues?.[filter.id]) {
    nextModel.filterModelValues[filter.id] = {}
  }

  if (value === undefined) {
    return nextModel
  }

  nextModel.filterModelValues[filter.id]![timeKey] = { ...value, valid: true }
  return nextModel
}

export const getValueFromTheModel = <T extends FilterValue = FilterValue>(
  model: FilterModel,
  filterId: string,
  timeDimensionValueId: Maybe<string>,
): T | undefined => {
  const timeKey = timeDimensionValueId ?? NO_TIME_SPREAD_FILTER

  return model.filterModelValues?.[filterId]?.[timeKey] as T | undefined
}

export const cleanUpModel = (
  model: FilterModel,
  actualMetadata: FilterModel['metadata'],
): FilterModel => {
  if (!model?.filterModelValues || !actualMetadata.filters?.length) {
    return DEFAULT_FILTERS_VALUE_MODEL
  }
  const filtersToUse = keyBy(actualMetadata.filters ?? {}, 'id')
  const values = Object.entries(model.filterModelValues)

  const validatedValues = compact(
    values.map(([filterKey, filterValues]) => {
      const filter = filtersToUse[filterKey]

      if (!filterValues) {
        return null
      }

      if (!filter) {
        return [filterKey, assignValuesInHash(filterValues, { valid: false })]
      }

      // If value is not spread by time dimension
      if (
        !filter.time_dimension_id &&
        filterValues?.[NO_TIME_SPREAD_FILTER] !== undefined
      ) {
        return [filterKey, assignValuesInHash(filterValues, { valid: true })]
      }

      return [
        filterKey,
        assignValuesInHash(filterValues, {
          valid: filter?.time_dimension_id === actualMetadata.time_dimension_id,
        }),
      ]
    }),
  )

  return isEqual(validatedValues, values)
    ? model
    : {
        ...model,
        filterModelValues: Object.fromEntries(validatedValues),
      }
}

export const getFilterValueAsSelectedDimensions = (
  model: FilterModel,
  filter: Filter,
): string[] => {
  if (filter.type !== 'dimension') {
    throw new Error('Cannot get selected dimensions for non-dimension filter')
  }

  const unselected = getValueFromTheModel<FilterValue<string[]>>(
    model,
    filter.id,
    filter.time_dimension_id ?? null,
  )?.value

  const allSelectedOptions = filter.options.map((i) => i.value)

  if (!isArray(unselected)) {
    return allSelectedOptions
  }

  return filter.options
    .filter(({ value }) => !unselected.includes(value))
    .map(({ value }) => value)
}

export const NO_TIME_SPREAD_FILTER = 'all'

const assignValuesInHash = (
  hash: Partial<Record<string, Record<string, unknown>>>,
  assigned: Partial<Record<string, unknown>>,
): Partial<Record<string, Record<string, unknown> & typeof assigned>> =>
  Object.fromEntries(
    Object.entries(hash).map(([k, v]) => [k, { ...v, ...assigned }]),
  )
