import { Maybe } from '@fintastic/shared/util/types'
import { ChartColumnData } from '@fintastic/web/feature/charts'
import { EChartsOption } from 'echarts'
import { ChartStyle } from './chart-style'

// Label      |  time 1   |  time 2  |  time 3
// Row A v1   |     1     |    2     |    3
// Row A v2   |     3     |    9     |    11
// Row B v1   |     4     |  null    |    6
// Row B v2   |   null    |    1     |    2
// ...

export type HeaderRow = string[]
export type DataRow = [label: string, ...indexes: number[]]

const extractChartData = (data: Maybe<ChartColumnData>) => {
  if (!data || data.length <= 1) {
    return {
      headers: [],
      meaningRows: [],
    }
  }

  const headers = (data[0] as HeaderRow)
    .toSpliced(0, 1)
    .map((v) => v.replaceAll(' ', '\n'))

  const meaningRows = (data as DataRow[]).toSpliced(0, 1)

  return { headers, meaningRows }
}

export const encodeForLines = (
  data: Maybe<ChartColumnData>,
): Maybe<EChartsOption> => {
  if (!data || data.length <= 1) {
    return null
  }
  const { headers, meaningRows } = extractChartData(data)

  const series: EChartsOption['series'] = meaningRows.map((row) => ({
    name: row[0],
    type: 'line',
    data: row.toSpliced(0, 1) as number[],
    label: {
      show: false,
      position: 'top',
    },
  }))

  const axisXLabel = (ChartStyle.xAxis as any).axisLabel || {}

  return {
    ...ChartStyle,
    xAxis: {
      ...ChartStyle.xAxis,
      data: headers,
      axisLabel: {
        ...axisXLabel,
        rotate: headers.length > 2 ? 90 : 0,
      },
    },
    yAxis: {
      ...ChartStyle.yAxis,
      type: 'value',
    },
    series,
  } as EChartsOption
}

export const encodeForBars = (
  data: Maybe<ChartColumnData>,
): Maybe<EChartsOption> => {
  if (!data || data.length <= 1) {
    return null
  }
  const { headers, meaningRows } = extractChartData(data)

  const series: EChartsOption['series'] = meaningRows.map((row) => ({
    name: row[0],
    type: 'bar',
    data: row.toSpliced(0, 1),
    label: {
      show: false,
      position: 'top',
    },
  }))

  const axisXLabel = (ChartStyle.xAxis as any).axisLabel || {}

  return {
    ...ChartStyle,
    xAxis: {
      ...ChartStyle.xAxis,
      data: headers,
      axisLabel: {
        ...axisXLabel,
        rotate: headers.length > 2 ? 90 : 0,
      },
    },
    yAxis: {
      ...ChartStyle.yAxis,
      type: 'value',
    },
    series,
  } as EChartsOption
}
