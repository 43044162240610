import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { ChartDebugDataTable } from '../../debug/ChartDebugDataTable/ChartDebugDataTable'
import { ChartDebugParams } from '../../debug/ChartDebugParams/ChartDebugParams'
import { Chart } from '../../../../ChartComponent/Chart'
import {
  ChartColumnData,
  ChartDisplayMode,
  ChartDisplaySettings,
  ChartRequestParams,
} from '../../../../../types'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { EmptyFolderErrorIcon } from '@fintastic/shared/ui/icons'
import {
  StyledChartContent,
  StyledChartError,
  StyledChartPanel,
} from './MetricChartPanels.styled'

type MetricChartPanelsProps = {
  data: Maybe<ChartColumnData>
  displayMode: ChartDisplayMode
  loading: boolean
  isDesignMode?: boolean
  displaySettings: ChartDisplaySettings
  params: Record<string, unknown>
  request: ChartRequestParams
}

// @todo: get rid of panels after charts stabilized
export const MetricChartPanels: React.FC<MetricChartPanelsProps> = ({
  data,
  displayMode,
  loading,
  isDesignMode,
  displaySettings,
  params,
  request,
}) => {
  const shouldShowNoData = !loading && (!data || data.length === 0)

  if (shouldShowNoData) {
    return (
      <StyledChartError>
        <ErrorAlert
          title={"Chart doesn't exist in the selected versions"}
          message={'Try selecting another version'}
          severity={'info'}
          icon={<EmptyFolderErrorIcon />}
        />
      </StyledChartError>
    )
  }

  return (
    <StyledChartContent data-testid="chart-content">
      <StyledChartPanel>
        {displayMode === 'chart' && (
          <Chart
            isDesignMode={isDesignMode}
            displaySettings={displaySettings}
            data={data}
          />
        )}

        {displayMode === 'table' && <ChartDebugDataTable data={data} />}

        {displayMode === 'debug' && (
          <ChartDebugParams params={params} request={request} data={data} />
        )}
      </StyledChartPanel>
    </StyledChartContent>
  )
}
