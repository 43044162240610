import {
  ColDef,
  EditableCallback,
  EditableCallbackParams,
  ValueFormatterFunc,
  ValueFormatterParams,
} from 'ag-grid-community'
import dayjs from 'dayjs'
import { DEFAULT_DAYJS_DATE_FORMAT } from '@fintastic/shared/util/date'
import type {
  BaseGridAgChartDataType,
  BaseGridColumnDefBuilderContext,
  BuildBaseGridColumnDefinitionParams,
  BuildBaseGridColumnGroupDefinitionParams,
} from '../types'
import {
  AgGridColDefBuilder,
  ColDefBuilderMatch,
  defaultComparator,
} from '@fintastic/shared/util/ag-grid'
import {
  AgGridCustomCellEditorName,
  AgGridCustomCellRendererName,
  AgGridDateCellEditorProps,
  AgGridDefaultCellRendererProps,
  AgGridNumericCellEditorProps,
  agGridNumericEditorDefaultCurrencyMask,
  agGridNumericEditorDefaultNumberMask,
  agGridNumericEditorDefaultPercentMask,
  resolveAggregation,
} from '@fintastic/shared/ui/ag-grid'
import { formatNumeric } from '@fintastic/shared/util/formatters'
import {
  BLANK_VALUE_UI_REPRESENTATION,
  containsBlankValue,
  containsMaskedValue,
  isRawBlankValue,
  MASKED_VALUE_UI_REPRESENTATION,
} from '@fintastic/web/util/blanks-and-masked'
import type { Maybe } from '@fintastic/shared/util/types'
import {
  createValueFormatter,
  MetricNumericDataValueType,
} from '@fintastic/web/util/metrics-and-lists'

const isValidIndexType = (x: unknown): x is string | number => {
  const tp = typeof x
  return tp === 'string' || tp === 'number'
}

const tryNumber = (val: unknown): number => {
  if (typeof val === 'number') {
    return val
  }

  if (typeof val === 'string') {
    return parseFloat(val)
  }

  return Number.NaN
}

// If symbol is supplied, returns its visual representation as a string
const formatPotentiallySymbol = (value: unknown) => {
  if (isRawBlankValue(value) || containsBlankValue(value)) {
    return BLANK_VALUE_UI_REPRESENTATION
  }

  if (containsMaskedValue(value)) {
    return MASKED_VALUE_UI_REPRESENTATION
  }

  return false
}

export const applyValueFormatter = (
  params: ValueFormatterParams,
  alternativeFormatter: ValueFormatterFunc,
) => {
  if (params.value === '' || params.value === undefined) {
    return ''
  }

  const maybeSymbol = formatPotentiallySymbol(params.value)

  if (maybeSymbol) {
    return maybeSymbol
  }

  return `${alternativeFormatter(params) || ''}`
}

export const tryDateTitle = (title: string) => {
  const dayjsObj = dayjs(title)
  // todo: add more format support weekly, yearly, etc.
  return dayjsObj.isValid() ? dayjsObj.format('MMM YYYY') : title
}

export const baseGridDefaultColumnMatch = (
  context: BaseGridColumnDefBuilderContext = {},
): ColDefBuilderMatch<BuildBaseGridColumnDefinitionParams> => ({
  matcher: () => true,
  builder: (colDef, params) => {
    const {
      title,
      field,
      rowDrag,
      isEditable,
      tooltip,
      cellRendererParams,
      comparator,
      headerComponent,
      headerComponentParams,
      suppressPaste,
      filterParams,
    } = params
    const cellEditor: AgGridCustomCellEditorName = 'textCellEditor'
    const cellRenderer: AgGridCustomCellRendererName = 'defaultCellRenderer'
    const mergedCellRendererParams: AgGridDefaultCellRendererProps = {
      showTooltipForValue: true,
      populateForward: params.populateForward,
      getClearValue: params.getCellClearValue,
      ...cellRendererParams,
    }

    const editableCallback: EditableCallback = (editableCbParams) =>
      isEditable ? isEditable(editableCbParams) : false

    return {
      ...colDef,
      editable: editableCallback,
      colId: field,
      field: field,
      rowDrag: rowDrag || false,
      headerName: title,
      headerComponent: headerComponent
        ? headerComponent
        : 'defaultColumnHeader',
      headerTooltip: headerComponent ? undefined : tooltip || title,
      comparator: comparator || defaultComparator,
      cellClass: (p) => (editableCallback(p) ? [] : ['readonly']),
      headerComponentParams,
      headerClass: (p) =>
        editableCallback(p as EditableCallbackParams<any>) ? [] : ['readonly'],
      cellEditor,
      cellRenderer,
      cellRendererParams: mergedCellRendererParams,
      filterParams,
      menuTabs: ['generalMenuTab', 'filterMenuTab'],
      aggFunc: resolveAggregation(params.rollUpFunction),
      suppressPaste,
      // Handle BlankOrMasked value by defaul
      valueFormatter: (params) => {
        const maybeSymbol = formatPotentiallySymbol(params.value)
        if (maybeSymbol) {
          return maybeSymbol
        }

        return params.value
      },
      ...(params.isRowId && { pinned: true }),
    }
  },
})

export const baseGridColumnsMatches = (
  context: BaseGridColumnDefBuilderContext = {},
): ColDefBuilderMatch<BuildBaseGridColumnDefinitionParams>[] => [
  {
    matcher: (params) => true,
    builder: (
      colDef,
      {
        pinned,
        initialPinned,
        lockPinned,
        valueFormatter,
        valueSetter,
        valueGetter,
        cellRenderer,
        enableRowGroup,
        isResizable,
        width,
        minWidth,
        maxWidth,
        defaultSort,
        title,
        tooltip,
        disableFilter,
        hide,
        isRequired,
        filterParams,
        cellRendererParams,
        filter,
      },
    ) => {
      const newColDef = {
        ...colDef,
        minWidth: minWidth === undefined ? 122 : minWidth,
        maxWidth,
      }

      if (pinned !== undefined) {
        newColDef.pinned = pinned
      }

      if (initialPinned !== undefined) {
        newColDef.initialPinned = initialPinned
      }

      if (lockPinned !== undefined) {
        newColDef.lockPinned = lockPinned
      }

      if (valueFormatter !== undefined) {
        newColDef.valueFormatter = valueFormatter
      }

      if (valueGetter !== undefined) {
        newColDef.valueGetter = valueGetter
      }

      if (valueSetter !== undefined) {
        newColDef.valueSetter = valueSetter
      }

      if (cellRenderer !== undefined) {
        newColDef.cellRenderer = cellRenderer
      }

      if (filterParams !== undefined) {
        newColDef.filterParams = filterParams
      }

      if (enableRowGroup !== undefined) {
        newColDef.enableRowGroup = enableRowGroup
      }

      if (filter !== undefined) {
        newColDef.filter = filter
      }

      if (isResizable !== undefined) {
        newColDef.resizable = isResizable
      }

      if (width !== undefined) {
        newColDef.initialWidth = width
      }

      if (defaultSort !== undefined) {
        newColDef.sort = defaultSort
      }

      if (title !== undefined) {
        newColDef.headerName = title
        newColDef.headerTooltip = tooltip
          ? tooltip
          : typeof newColDef.headerComponent === 'string'
          ? title
          : undefined
      }

      if (disableFilter) {
        newColDef.filter = false
      }

      newColDef.hide = !!hide

      newColDef.cellRendererParams = {
        ...(newColDef.cellRendererParams || {}),
        ...(cellRendererParams || {}),
      }

      if (isRequired !== undefined) {
        newColDef.cellRendererParams.isRequired = isRequired
      }

      return newColDef
    },
  },

  {
    matcher: (params) => params.dataType === 'string',
    builder: (colDef, params) => ({
      ...colDef,
      filter: params.disableFilter
        ? false
        : colDef.filter || 'agTextColumnFilter',
      chartDataType: 'category',
    }),
  },
  {
    matcher: (params) => params.dataType === 'boolean',
    builder: (colDef, params) => ({
      ...colDef,
      filter: params.disableFilter
        ? false
        : colDef.filter || 'agNumberColumnFilter',
    }),
  },

  {
    matcher: (params) =>
      params.dataType === 'number' ||
      params.dataType === 'integer' ||
      params.dataType === 'currency' ||
      params.dataType === 'percentage' ||
      params.dataType === 'percentage_integer',
    builder: (colDef, params) => {
      const cellEditorProps: AgGridNumericCellEditorProps = {
        mask: params.displaySettings
          ? undefined
          : agGridNumericEditorDefaultNumberMask,
        dataType: params.dataType as MetricNumericDataValueType,
        displaySettings: params.displaySettings,
        currency: params.currency,

        parseSourceValue: (value: Maybe<string>) => {
          if (isRawBlankValue(value)) {
            return value
          }

          const parsed = parseFloat(`${value}`)

          if (Number.isNaN(parsed)) {
            return ''
          }

          // IMask does not support doubles if no decimal_places
          if (
            params.displaySettings?.formatting?.decimal_places === 0 ||
            params.dataType === 'integer'
          ) {
            return `${Math.round(parsed)}`
          }
          return `${parsed}`
        },
        parseEditedValue: (value: Maybe<string>) => {
          if (isRawBlankValue(value)) {
            return value
          }

          const parsed = parseFloat(`${value}`)

          if (Number.isNaN(parsed)) {
            return ''
          }

          // IMask does not support doubles if no decimal_places
          if (
            params.dataType === 'integer' ||
            params.displaySettings?.formatting?.decimal_places === 0
          ) {
            return `${Math.round(parsed)}`
          }

          return parsed
        },
      }

      const valueFormatter = params.displaySettings
        ? createValueFormatter(
            params.dataType as never,
            params.displaySettings,
            params.currency,
          ) || undefined
        : (formatterParams: ValueFormatterParams) =>
            applyValueFormatter(formatterParams, ({ value }) =>
              formatNumeric.number(value),
            )

      return {
        ...colDef,
        type: 'numericColumn',
        filter: params.disableFilter ? false : 'agNumberColumnFilter',
        valueFormatter,
        chartDataType: 'series',
        cellEditor: 'numericCellEditor',
        cellEditorParams: cellEditorProps,
      }
    },
  },

  {
    matcher: (params) =>
      (params.dataType === 'number' && params.dataFormat === 'currency') ||
      params.dataType === 'currency',
    builder: (colDef, params) => {
      const cellEditorProps: AgGridNumericCellEditorProps = {
        mask: params.displaySettings
          ? undefined
          : agGridNumericEditorDefaultCurrencyMask,
        dataType: params.dataType as MetricNumericDataValueType,
        displaySettings: params.displaySettings,
        currency: params.currency,
      }

      const valueFormatter = params.displaySettings
        ? createValueFormatter(
            params.dataType as never,
            params.displaySettings,
            params.currency,
          ) || undefined
        : (formatterParams: ValueFormatterParams) =>
            applyValueFormatter(formatterParams, ({ value }) =>
              formatNumeric.currency(value || 0),
            )

      return {
        ...colDef,
        valueFormatter,
        cellEditorParams: {
          ...colDef.cellEditorParams,
          ...cellEditorProps,
        },
        chartDataType: 'series',
      }
    },
  },
  {
    matcher: (params) =>
      (params.dataType === 'number' && params.dataFormat === 'percent') ||
      params.dataType === 'percentage' ||
      params.dataType === 'percentage_integer',
    builder: (colDef, params) => {
      const cellEditorProps: AgGridNumericCellEditorProps = {
        mask: params.displaySettings
          ? undefined
          : agGridNumericEditorDefaultPercentMask,
        dataType: params.dataType as MetricNumericDataValueType,
        displaySettings: params.displaySettings,
        parseSourceValue: (value: Maybe<string>) => {
          if (isRawBlankValue(value)) {
            return value
          }
          const parsed = parseFloat(`${value}`)
          return Number.isNaN(parsed) ? '' : `${parsed * 100}`
        },
        parseEditedValue: (value: Maybe<string>) => {
          if (isRawBlankValue(value)) {
            return value
          }
          const parsed = parseFloat(`${value}`)
          return Number.isNaN(parsed) ? '' : parsed / 100
        },
      }

      const valueFormatter = params.displaySettings
        ? createValueFormatter(
            params.dataType as never,
            params.displaySettings,
          ) || undefined
        : (formatterParams: ValueFormatterParams) =>
            applyValueFormatter(formatterParams, ({ value }) =>
              formatNumeric.percentage(value),
            )

      return {
        ...colDef,
        valueFormatter,
        cellEditorParams: {
          ...colDef.cellEditorParams,
          ...cellEditorProps,
        },
        chartDataType: 'series',
      }
    },
  },
  {
    matcher: (params) =>
      params.dataType === 'date' || params.dataType === 'datetime',
    builder: (colDef, params) => {
      const comparator = (filterValue: Date, cellValue: Date): number => {
        const filterValueWoTime = new Date(filterValue.valueOf())
        filterValueWoTime.setHours(0, 0, 0, 0)
        const cellValueWoTime = new Date(cellValue.valueOf())
        cellValueWoTime.setHours(0, 0, 0, 0)
        if (filterValueWoTime.getTime() === cellValueWoTime.getTime()) {
          return 0
        }
        return cellValueWoTime.getTime() > filterValueWoTime.getTime() ? 1 : -1
      }
      return {
        ...colDef,
        filter: params.disableFilter ? false : 'agDateColumnFilter',
        filterParams: {
          comparator: comparator,
          inRangeInclusive: true,
        },
      }
    },
  },
  {
    matcher: (params) => params.dataType === 'date',
    builder: (colDef, params) => {
      const cellEditor: AgGridCustomCellEditorName = 'dateCellEditor'
      const cellEditorProps: AgGridDateCellEditorProps = {
        displayingFormat: dayjsFormatToDateFnsFormat(
          params.dataFormat || DEFAULT_DAYJS_DATE_FORMAT,
        ),
        storingFormat: dayjsFormatToDateFnsFormat(DEFAULT_DAYJS_DATE_FORMAT),
      }
      const valueFormatter = (formatterParams: ValueFormatterParams) =>
        applyValueFormatter(formatterParams, ({ value }) =>
          value
            ? dayjs(value).format(
                params.dataFormat || DEFAULT_DAYJS_DATE_FORMAT,
              )
            : '',
        )
      return {
        ...colDef,
        valueFormatter,
        getQuickFilterText: valueFormatter,
        cellEditor,
        cellEditorParams: {
          ...colDef.cellEditorParams,
          ...cellEditorProps,
        },
        chartDataType: 'category',
      }
    },
  },
  {
    // @todo(@mykola): As a hot fix we get remove of time from formatting
    // This function should use DEFAULT_DAYJS_DATE_TIME_FORMAT
    // But it caused an issue FIN-6011
    // Perhaps, API should accept just `date`, not `datetime`
    matcher: (params) => params.dataType === 'datetime',
    builder: (colDef, params) => {
      const cellEditor: AgGridCustomCellEditorName = 'dateCellEditor'
      const cellEditorProps: AgGridDateCellEditorProps = {
        displayingFormat: dayjsFormatToDateFnsFormat(
          params.dataFormat || DEFAULT_DAYJS_DATE_FORMAT,
        ),
        storingFormat: dayjsFormatToDateFnsFormat(DEFAULT_DAYJS_DATE_FORMAT),
      }
      const valueFormatter = (formatterParams: ValueFormatterParams) =>
        applyValueFormatter(formatterParams, ({ value }) =>
          value
            ? dayjs(value).format(
                params.dataFormat || DEFAULT_DAYJS_DATE_FORMAT,
              )
            : '',
        )
      return {
        ...colDef,
        valueFormatter,
        getQuickFilterText: valueFormatter,
        cellEditor,
        cellEditorParams: {
          ...colDef.cellEditorParams,
          ...cellEditorProps,
        },
        chartDataType: 'category' as BaseGridAgChartDataType,
      }
    },
  },
  {
    matcher: (params) => params.dataType === 'string',
    builder: (colDef) => {
      // Override default clearable value from null to an empty string
      const cellEditorProps = {
        getClearValue: () => '',
      }

      return {
        ...colDef,
        cellEditorParams: {
          ...(colDef.cellEditorParams || {}),
          ...cellEditorProps,
        },
      }
    },
  },
  {
    matcher: (params) => params.dataType === 'dimension',
    builder: (colDef, params) => ({
      ...colDef,
      filter: 'agSetColumnFilter',
      comparator: (a, b) => {
        const valA = isValidIndexType(a)
          ? (params as any)?.['options']?.[a]
          : undefined

        const valB = isValidIndexType(b)
          ? (params as any)?.['options']?.[b]
          : undefined

        const maybeNumberA = tryNumber(valA)
        const maybeNumberB = tryNumber(valB)

        if (!Number.isNaN(maybeNumberA) && !Number.isNaN(maybeNumberB)) {
          // compare both as numbers, options[DimId] = 1 vs options[DimId] = 2
          return maybeNumberA - maybeNumberB
        }

        if (typeof valA !== 'undefined' && typeof valB !== 'undefined') {
          // lookup text values found but not as numeric values - compare as strings
          return ('' + valA).localeCompare('' + valB)
        }

        // fallback: compare raw DimIds as strings
        return String(a).localeCompare(String(b))
      },
      cellEditor: 'selectBoxCellEditor',
      cellEditorParams: params.cellEditorParams,
      valueFormatter: (p) =>
        ((params?.cellEditorParams?.['options'] as any[]) || []).find(
          (i) => i.value === p.value,
        )?.label || p.value,
    }),
  },
  {
    matcher: (params) => params.dataType === 'boolean',
    builder: (colDef, params) => ({
      ...colDef,
      filter: 'agSetColumnFilter',
      cellEditor: 'checkboxCellEditor',
      cellEditorParams: params.cellEditorParams,
      cellRenderer: 'checkboxCellRenderer',
    }),
  },
]

export const buildBaseGridColumnDefinition =
  <TData = any>(context: BaseGridColumnDefBuilderContext = {}) =>
  (params: BuildBaseGridColumnDefinitionParams): ColDef<TData> => {
    const matches = baseGridColumnsMatches(context)

    const builder =
      new AgGridColDefBuilder<BuildBaseGridColumnDefinitionParams>([
        baseGridDefaultColumnMatch(context),
        ...matches,
      ])

    return builder.build(params)
  }

export const buildGroupOfColumns =
  <TData = any>(columnBuilder: (params: any) => ColDef<TData>) =>
  (groupColumn: BuildBaseGridColumnGroupDefinitionParams) => ({
    headerName: groupColumn.title,
    children: groupColumn.children.map(columnBuilder),
  })

/** @deprecated */
function dayjsFormatToDateFnsFormat(dayjsFormat: string): string {
  return dayjsFormat
    .replace('YYYY', 'yyyy')
    .replace('DDDD', 'ddd')
    .replace('DDD', 'ddd')
    .replace('DD', 'dd')
    .replace('D', 'd')
}
