import { FormField } from '@fintastic/shared/ui/form-framework'
import React, { memo, useCallback } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { MetricChartGrouping } from './controls'
import { type ChartSettings, MetricChartDimensions } from '../../types'
import { OnChangeChartSettings } from '../../internal-types'

export type MetricChartFormProps = {
  metricId: string
  loading?: boolean
  version?: Maybe<string>
  initialSettings?: Maybe<ChartSettings>
  chartSettings: Maybe<ChartSettings>
  onChangeChartSettings: OnChangeChartSettings
  withTitle?: boolean
}

export const MetricChartForm: React.FC<MetricChartFormProps> = memo(
  ({
    metricId,
    loading,
    version,
    chartSettings,
    onChangeChartSettings,
    withTitle,
  }) => {
    const handleSetDimensions = useCallback(
      (dimensions: MetricChartDimensions, initial?: boolean) => {
        onChangeChartSettings({
          dimensions,
        })
      },
      [onChangeChartSettings],
    )

    const showColumnsOrdering = !!metricId && !!version

    if (!showColumnsOrdering) {
      return null
    }

    return (
      <FormField fullWidth={true}>
        <MetricChartGrouping
          disabled={loading}
          currentDimensions={chartSettings?.dimensions || []}
          onColumnsUpdate={handleSetDimensions}
          withTitle={withTitle}
        />
      </FormField>
    )
  },
)
