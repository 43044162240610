import React, { useCallback, useState } from 'react'
import {
  FormField,
  FormLayout,
  TextField,
} from '@fintastic/shared/ui/form-framework'
import { ReferenceVersionSelect } from './controls/ReferenceVersionSelect/ReferenceVersionSelect'
import { Maybe } from '@fintastic/shared/util/types'
import { WidgetSelect } from './controls/WidgetSelect/WidgetSelect'
import { ChangeWidgetDialogParams } from '../../../../types/board-design-types'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import {
  ChartThemeSelect,
  ChartTypeSelect,
  defaultChartTheme,
  defaultChartType,
  MetricChartFormWithDataSource,
} from '@fintastic/web/feature/charts'
import { useWidgetSettings } from './hooks/useWidgetSettings'

export type BoardAddWidgetFormProps = {
  params?: ChangeWidgetDialogParams
  closeParentModal?: () => void
  onConfirm: (params: ChangeWidgetDialogParams) => void
}

export const BoardChangeWidgetForm: React.FC<BoardAddWidgetFormProps> = (
  props,
) => {
  const { closeParentModal, onConfirm, params } = props

  const {
    versionId,
    reportId,
    metricId,
    listId,
    entityId,
    chartSettings,
    widgetType,
    displayNameFieldState,

    setVersionId,
    handleSelectVersion,
    handleSelectList,
    handleSelectMetric,
    handleSelectReport,
    handleChartSettingsChange,
    setChanged,

    isNew,
    isMetricChartWidget,
    isOneOfMetricWidgets,
    isReportWidget,
    isListWidget,
    isVersionDependentWidget,

    isSubmitPossible,
    hasVersion,
    hasMetric,
  } = useWidgetSettings(params)

  const [versionMessageShown, setVersionMessageShown] = useState(false)

  const handleNoRefVersionMessage = useCallback(() => {
    if (!versionMessageShown) {
      setVersionMessageShown(() => true)
      setVersionId(() => null)
      setChanged(() => true)
    }
  }, [setChanged, setVersionId, versionMessageShown])

  const handleSelectNewVersion = useCallback(
    (versionId: Maybe<string>) => {
      handleSelectVersion(versionId)
      setVersionMessageShown(false)
    },
    [handleSelectVersion],
  )

  const [isVersionDependentSelectPending, setVersionDependentSelectPending] =
    useState(false)

  const [isDimensionListPending, setIsDimensionListPending] = useState(false)

  const [dimensionsLoadingError, setDimensionsLoadingError] = useState('')

  const isSubmitEnabled =
    isSubmitPossible &&
    (isMetricChartWidget ? !isDimensionListPending : true) &&
    (isVersionDependentWidget ? !isVersionDependentSelectPending : true)

  const shouldShowNoVersionWarning =
    !versionId &&
    !versionMessageShown &&
    isVersionDependentWidget &&
    (Boolean(metricId) || Boolean(listId))

  const handleSubmit = useCallback(() => {
    // versionId and entityId are guaranteed by isSubmitEnabled
    onConfirm({
      type: widgetType,
      versionId: versionId || '',
      id: entityId || '',
      widgetId: props.params?.widgetId,
      displayLabel: displayNameFieldState.trimmedValue || '',
      chartSettings:
        isMetricChartWidget && chartSettings ? chartSettings : undefined,
    })
  }, [
    versionId,
    entityId,
    onConfirm,
    widgetType,
    props.params?.widgetId,
    displayNameFieldState.trimmedValue,
    isMetricChartWidget,
    chartSettings,
  ])

  return (
    <FormLayout
      submitEnabled={isSubmitEnabled}
      submitButtonText={isNew ? 'Continue' : 'Save'}
      onSubmit={handleSubmit}
      onCancel={closeParentModal}
    >
      <FormField fullWidth={true}>
        <ReferenceVersionSelect
          value={versionId}
          onNoRefVersion={handleNoRefVersionMessage}
          onSelect={handleSelectNewVersion}
        />
      </FormField>

      {isReportWidget && (
        <FormField fullWidth={true}>
          <WidgetSelect
            mode={'report'}
            value={reportId}
            onSelect={handleSelectReport}
          />
        </FormField>
      )}

      {isListWidget && (
        <FormField fullWidth={true}>
          <WidgetSelect
            mode={'list'}
            disabled={!versionId}
            versionId={versionId}
            value={listId}
            onSelect={handleSelectList}
            onLoadingUpdate={setVersionDependentSelectPending}
          />
        </FormField>
      )}

      {isOneOfMetricWidgets && (
        <FormField fullWidth={true}>
          <WidgetSelect
            mode={'metric'}
            disabled={!versionId}
            versionId={versionId}
            value={metricId}
            onSelect={handleSelectMetric}
            onLoadingUpdate={setVersionDependentSelectPending}
          />
        </FormField>
      )}

      <TextField
        label="Display name"
        fullWidth
        placeholder="Display name"
        value={displayNameFieldState.value}
        onChange={displayNameFieldState.setValue}
        error={
          displayNameFieldState.touched
            ? !displayNameFieldState.trimmedValue
            : false
        }
        errorMessage={
          displayNameFieldState.touched && !displayNameFieldState.trimmedValue
            ? 'Mandatory field'
            : undefined
        }
      />

      {isMetricChartWidget && (
        <>
          <ChartTypeSelect
            chartType={chartSettings?.type || defaultChartType}
            disabled={!hasMetric}
            onChangeChartSettings={handleChartSettingsChange}
          />

          <ChartThemeSelect
            chartTheme={chartSettings?.theme || defaultChartTheme}
            disabled={!hasMetric}
            onChangeChartSettings={handleChartSettingsChange}
          />
        </>
      )}

      {isMetricChartWidget && hasMetric && hasVersion && (
        <>
          <MetricChartFormWithDataSource
            metricId={metricId || ''}
            initialSettings={chartSettings || null}
            chartSettings={chartSettings}
            onChangeChartSettings={handleChartSettingsChange}
            version={versionId}
            onLoadingUpdate={setIsDimensionListPending}
            onError={setDimensionsLoadingError}
            withTitle={true}
          />

          {Boolean(dimensionsLoadingError) && (
            <div style={{ marginTop: 16 }}>
              <ErrorAlert title="Error" message={dimensionsLoadingError} />
            </div>
          )}
        </>
      )}

      {shouldShowNoVersionWarning && (
        <div style={{ marginTop: 16 }}>
          <ErrorAlert
            title="The reference version is not set."
            message="Please select a version."
            severity="info"
          />
        </div>
      )}

      {versionMessageShown && (
        <div style={{ marginTop: 16 }}>
          <ErrorAlert
            title="The reference version has been archived or deleted."
            message="Please select another version."
            severity="warning"
          />
        </div>
      )}
    </FormLayout>
  )
}
