import { Maybe } from '@fintastic/shared/util/types'
import { Query, useQuery, useQueryClient } from 'react-query'
import { getListColumns } from '../api/lists-api'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { metricsAndListsCacheKeys } from '../cache'
import { useEffect, useRef } from 'react'
import { AxiosError } from 'axios'
import {
  PeriodSelection,
  isEmptyPeriodSelection,
} from '@fintastic/web/util/period-selector'
import { FilterListAPIPayload } from '@fintastic/web/util/filters'

/** @deprecated */
export function useLoadListColumns(
  versionId?: Maybe<string>,
  listId?: Maybe<string>,
  columnIds?: Maybe<string[]>,
  periodSelection?: PeriodSelection,
  filters: FilterListAPIPayload = {},
  enabled = true,
) {
  const queryClient = useQueryClient()

  const columnsManualCache = useRef<ManualColumnsDataCache>()

  if (!columnsManualCache.current) {
    columnsManualCache.current = new ManualColumnsDataCache()
  }

  useEffect(() => {
    queryClient.invalidateQueries(
      metricsAndListsCacheKeys.listColumnsLoader(
        versionId,
        listId,
        columnIds,
        periodSelection,
      ),
    )
  }, [columnIds, listId, queryClient, periodSelection, versionId])

  return useQuery<Metric[], AxiosError | Error>(
    metricsAndListsCacheKeys.listColumnsLoader(
      versionId,
      listId,
      columnIds,
      periodSelection,
      filters,
    ),
    async () => {
      if (!versionId || !listId || !columnIds) {
        throw new Error('required params are not defined')
      }

      const columnsData: Metric[] = []
      const invalidColumns: string[] = []

      columnIds.forEach((id) => {
        const columnCache = queryClient
          .getQueryCache()
          .find<Metric>(
            metricsAndListsCacheKeys.listColumn(
              versionId,
              id,
              periodSelection,
              filters,
            ),
          )

        if (
          !columnCache ||
          !columnCache.state.data ||
          columnCache.state.isInvalidated
        ) {
          invalidColumns.push(id)
          return
        }

        columnsData.push(
          columnsManualCache.current?.addOrUpdate(
            id,
            columnCache.state.data,
            columnCache.state.dataUpdatedAt,
          ) || columnCache.state.data,
        )
      })

      if (invalidColumns.length) {
        const loadedColumns = await getListColumns(
          versionId,
          listId,
          invalidColumns,
          periodSelection,
          true,
          undefined,
          filters,
        )
        loadedColumns.data.result.forEach((loadedColumn) => {
          const queryKey = metricsAndListsCacheKeys.listColumn(
            versionId,
            loadedColumn.id,
            periodSelection,
            filters,
          )

          // creates query if it doesn't exist
          queryClient.setQueryData(queryKey, loadedColumn)
          // let's provide some default options to this query
          const queryCache = queryClient.getQueryCache().find(queryKey) as Query
          queryCache.setDefaultOptions({ structuralSharing: false })

          columnsData.push(
            columnsManualCache.current?.addOrUpdate(
              loadedColumn.id,
              loadedColumn,
              queryCache.state.dataUpdatedAt,
            ) as Metric,
          )
        })
      }

      return columnsData
    },
    {
      enabled:
        enabled &&
        !!versionId &&
        !!listId &&
        !!columnIds &&
        !isEmptyPeriodSelection(periodSelection ?? null),
      staleTime: Infinity,
      structuralSharing: false, // we really need a new reference if something was loaded
      retry: 1,
      keepPreviousData: true,
    },
  )
}

class ManualColumnsDataCache {
  private columns: Record<
    string,
    {
      data: Maybe<Metric>
      dataUpdatedAt: number
    }
  > = {}

  public addOrUpdate(
    id: string,
    data: Maybe<Metric>,
    updatedAt: number,
  ): Maybe<Metric> {
    const existingRecord = this.columns[id]

    if (!existingRecord || existingRecord.dataUpdatedAt !== updatedAt) {
      this.columns[id] = {
        data,
        dataUpdatedAt: updatedAt,
      }
    }

    return this.columns[id].data
  }
}
