import { Dimension } from '@fintastic/shared/util/types'
import { DimensionType } from '@fintastic/web/util/dimensions'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { MetricDataValue } from '@fintastic/web/util/metrics-and-lists'

export const ChartTypes = {
  bars: { name: 'Bar chart' },
  lines: { name: 'Line chart' },
  // @todo: for the future
  // stackedBars: { name: 'Stacked bar chart' },
  // waterfall: { name: 'Waterfall chart' },
}

export type ChartType = keyof typeof ChartTypes

function createKeys<T extends string | number | symbol>(
  keyRecord: Record<T, any>,
): T[] {
  return Object.keys(keyRecord) as any
}

export const ChartTypeKeys: ChartType[] = createKeys<ChartType>(ChartTypes)

export function isChartType(value: unknown): value is ChartType {
  return ChartTypes[value as ChartType] !== undefined
}

export const ChartThemes = {
  fintastic: 'fintastic',
  light: 'Light',
  ocean: 'Ocean',
  spring: 'Spring',
  vintage: 'Vintage',
}

export type ChartTheme = keyof typeof ChartThemes

export const ChartThemeKeys: ChartTheme[] = createKeys<ChartTheme>(ChartThemes)

export function isChartTheme(value: unknown): value is ChartTheme {
  return ChartThemes[value as ChartTheme] !== undefined
}

export type MetricDimensionContent = Omit<Dimension, 'type' | 'values'> & {
  type: DimensionType | 'Version'
} & {
  aggregate?: boolean
  time_dimension?: boolean
}

export type MetricChartDimensions = MetricDimensionContent[]
export type MetricChartDimensionsEssential = {
  id: string
  aggregate: boolean
  time_dimension: boolean
}

export type ChartDisplayMode = 'chart' | 'table' | 'debug'

export type ChartRequestParams = {
  metricId: string
  versions: string[]
  periodSelection: PeriodSelection
  dimensions: MetricChartDimensionsEssential[]
}

export type ChartColumnData = MetricDataValue[][]

export type ChartDisplaySettings = {
  type?: ChartType
  theme?: ChartTheme
}

export type ChartDataSettings = {
  dimensions?: MetricChartDimensions
}

export type ChartSettings = ChartDisplaySettings & ChartDataSettings
