import React, { useEffect, useMemo, useState } from 'react'
import type { VersionsApi } from '../list-connector/features/versions-api/useVersionsApi'
import type { ListsApi } from '../list-connector/features/lists-api/useListsApi'
import {
  CollapseExpandButton as PanelFrameworkCollapseExpandButton,
  Panel,
  PanelBottomBar,
  PanelDragHandle,
} from '@fintastic/shared/ui/panel-framework'
import {
  SetGridSizeCallback,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  ColumnColorSelector,
  ColumnColorsGridProps,
  GridColumnColorsCssWrapper,
  ListIcon,
} from '@fintastic/web/feature/metrics-and-lists'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { PaginatedListGrid } from './PaginatedListGrid'
import { useBaseTimeDimensionCurrentValue } from '@fintastic/web/data-access/base-time-dimension'
import { ListGridPropsLocalVersion } from '../list-connector/features/versions-selector/types'
import {
  ErrorAlert,
  ModelExplorerVersionSelector,
  SingleVersionSelector,
} from '@fintastic/shared/ui/components'
import {
  usePeriodSelectorContext,
  usePeriodSelectorResolution,
} from '@fintastic/web/util/period-selector'
import { useLoadPaginatedListColumns } from '@fintastic/web/data-access/metrics-and-lists'
import {
  useVersionEntitiesContext,
  useVersionUserLockQuery,
} from '@fintastic/web/data-access/versions'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import {
  FormulaEditorRouter,
  FormulasTabContextProvider,
  useFormulasTabs,
} from '../../features/formulas'
import { FormulaButton, FormulaCollapse } from '@fintastic/web/feature/formulas'
import { SettingsPanelContextsWrapper } from '../list-grid/features/settings/SettingsPanelContextsWrapper'
import { useSettingsRouterLocation } from '../list-grid/features/settings/useSettingsRouterLocation'
import { useListEditorApi } from '@fintastic/web/feature/list-editor'
import { TimeDimensionValueId } from '@fintastic/web/util/dimensions'
import { resolveValueColumnColor } from '@fintastic/web/util/metrics-and-lists'
import { SelectedCellAggregationProvider } from '@fintastic/web/util/selected-cell-aggregation'
import { SelectedCellAggregation } from '@fintastic/web/feature/selected-cell-aggregation'
import { versionIsLockedMessage } from '@fintastic/web/util/versions'
import { useWidgetContext } from '@fintastic/shared/ui/widgets-framework'
import { useFilterContextApiPayload } from '@fintastic/web/util/filters'
import { getListGridError } from '../list-grid/features/errors/getListGridError'
import { mapListErrorToUi } from '../list-grid/features/errors/error-to-ui-mapping'

export type PaginatedListConnectorProps = {
  title: string
  listId: string
  versionsApi: VersionsApi
  listsApi: ListsApi
  periodSelectorComponent: React.ReactNode
  versionSelectorProps: ListGridPropsLocalVersion
  showSettingsPanel: boolean
  visibleColumnIds: string[]
  isVersionPage: boolean
  requestEntityDeletion?: (entityId: string) => void
  setGridSizeCallback?: SetGridSizeCallback
} & Partial<ColumnColorsGridProps>

export const PaginatedListConnector: React.FC<PaginatedListConnectorProps> = ({
  versionsApi,
  listsApi,
  listId,
  title,
  periodSelectorComponent,
  versionSelectorProps,
  showSettingsPanel,
  visibleColumnIds,
  isVersionPage,
  columnColors,
  enableColumnColors,
  handleUpdateColumnColors,
  requestEntityDeletion,
  setGridSizeCallback,
}) => {
  const widgetContext = useWidgetContext()

  const { versionEditable } = versionsApi
  const versionId = versionsApi.versionId || ''
  const { list, listOriginallyEmpty } = listsApi
  const baseTimeDimension = useBaseTimeDimensionCurrentValue()
  const periodSelection = usePeriodSelectorContext()
  const filters = useFilterContextApiPayload()
  const entitiesContextValue = useVersionEntitiesContext()
  const versionDimensions = useMemo(
    () =>
      entitiesContextValue.entities?.[versionId]?.dimensions.map(
        (d) => d.dimension,
      ) || [],
    [entitiesContextValue.entities, versionId],
  )

  const [page, setPage] = useState(1)
  const itemsPerPage = 500
  useEffect(() => {
    setPage(1)
  }, [versionsApi.versionId, periodSelection, filters])

  const formulasTabsState = useFormulasTabs()
  const titleText = useMemo(() => titleFormatter(title) || 'List', [title])

  const listEditorApi = useListEditorApi()
  const settingsRouterLocation = useSettingsRouterLocation(false)

  const columnsQuery = useLoadPaginatedListColumns(
    versionsApi.versionId && list
      ? {
          enabled: true,
          versionId,
          listId,
          columnIds: visibleColumnIds,
          periodSelection,
          page,
          itemsPerPage,
          filters,
        }
      : { enabled: false },
  )
  const visibleColumns = useMemo(
    () => columnsQuery.data?.result || [],
    [columnsQuery.data?.result],
  )
  const totalItems = columnsQuery.data?.total || 0
  const timeDimensionId = useMemo<Maybe<TimeDimensionValueId>>(
    () =>
      toMaybe(
        visibleColumns.find((c) => c.metadata.time_dimension_id)?.metadata
          .time_dimension_id,
      ),
    [visibleColumns],
  )
  const hasPeriodColumn = timeDimensionId !== null
  const allPeriodsList = useMemo(() => {
    if (!timeDimensionId) {
      return []
    }

    return (
      versionDimensions.find((d) => d.id === timeDimensionId)
        ?.orderedValuesIds || []
    )
  }, [timeDimensionId, versionDimensions])
  const showPeriodSelector = Boolean(baseTimeDimension) && hasPeriodColumn

  const isLoadingInitial =
    columnsQuery.isLoading || entitiesContextValue.isLoading
  const isLoadingPage = columnsQuery.isFetching
  const isEnteringEditMode = !!(
    !listEditorApi?.active && listEditorApi?.editList.running
  )

  const listAsConnectedTable = useMemo(() => ({ id: listId }), [listId])
  const calculatedColumnsMap = useMemo(
    () =>
      Object.fromEntries(
        visibleColumns
          .filter((c) => c.source === 'calculated')
          .map((c) => [c.id, c]),
      ),
    [visibleColumns],
  )

  const renderColumnColorSelector = useMemo(() => {
    if (
      !enableColumnColors ||
      columnColors === undefined ||
      !handleUpdateColumnColors
    ) {
      return null
    }
    return function (metricId: string, period: Maybe<TimeDimensionValueId>) {
      return (
        <ColumnColorSelector
          columnColors={columnColors}
          currentColumnColumn={resolveValueColumnColor(columnColors, {
            versionId,
            metricId: metricId,
            period: period,
          })}
          handleUpdateColumnColors={handleUpdateColumnColors}
        />
      )
    }
  }, [columnColors, enableColumnColors, handleUpdateColumnColors, versionId])

  const versionUserLockQuery = useVersionUserLockQuery(versionId)

  const grid = (
    <PaginatedListGrid
      versionId={versionId}
      columns={visibleColumns}
      versionDimensions={versionDimensions}
      rowDimension={list?.row_dim || ''}
      listSource={list?.source ?? null}
      isLiveActuals={versionsApi.isLiveActuals}
      showSettingsPanel={showSettingsPanel}
      versionUserLock={versionUserLockQuery.lock}
      renderColumnColorSelector={renderColumnColorSelector}
      setGridSizeCallback={setGridSizeCallback}
      listOriginallyEmpty={listOriginallyEmpty}
    />
  )

  const error = useMemo(
    () =>
      getListGridError(
        versionId,
        {
          listItselfError: listsApi.error,
          versionsApiError: versionsApi.error,
          columnsError: columnsQuery.error as Error,
        },
        columnsQuery.data?.result ?? null,
        {
          listItselfOriginallyEmpty: listOriginallyEmpty,
        },
      ),
    [
      columnsQuery.data?.result,
      columnsQuery.error,
      listOriginallyEmpty,
      listsApi.error,
      versionId,
      versionsApi.error,
    ],
  )

  const periodResolution = usePeriodSelectorResolution()

  const errorUiMapping = useMemo(
    () =>
      !error
        ? null
        : mapListErrorToUi(error, {
            periodResolution,
          }),
    [error, periodResolution],
  )

  const errorContent = errorUiMapping ? (
    <ErrorAlert {...errorUiMapping} />
  ) : undefined

  const editButton = useMemo(() => {
    if (!listEditorApi) {
      return null
    }
    if (listEditorApi.active || !listEditorApi.editList) {
      return null
    }

    if (versionUserLockQuery.lock.editIsBlocked) {
      return (
        <Tooltip
          title={versionIsLockedMessage(versionUserLockQuery.lock)}
          arrow
        >
          <span>
            <Button
              variant="outlined"
              color="black"
              sx={{ ml: 1 }}
              disabled={true}
            >
              Edit
            </Button>
          </span>
        </Tooltip>
      )
    }

    return (
      <Button
        variant="outlined"
        color="black"
        onClick={() => listEditorApi.editList.run(listId)}
        sx={{ ml: 1 }}
        disabled={
          isLoadingInitial ||
          isLoadingPage ||
          isEnteringEditMode ||
          versionUserLockQuery.isLoading
        }
      >
        {isEnteringEditMode && 'Loading...'}
        {!isEnteringEditMode && 'Edit'}
      </Button>
    )
  }, [
    isEnteringEditMode,
    isLoadingInitial,
    isLoadingPage,
    listEditorApi,
    listId,
    versionUserLockQuery,
  ])

  return (
    <SelectedCellAggregationProvider entityIdForPersistentSettings={listId}>
      <FormulasTabContextProvider value={formulasTabsState}>
        <SettingsPanelContextsWrapper
          list={list}
          editingAllowed={false}
          version={versionsApi.versionsMetadata[versionId]}
          location={settingsRouterLocation}
          allowExistingColumnsDeletion={Boolean(
            listEditorApi?.active
              ? false
              : listEditorApi?.allowedToEnterEditMode,
          )}
          requestEntityDeletion={requestEntityDeletion || console.log}
        >
          <Panel
            fullHeight={true}
            isLoading={isLoadingInitial}
            errorContent={errorContent}
            topbar={
              <>
                <TopBar
                  title={titleText}
                  titleIcon={
                    <Tooltip title="List">
                      <ListIcon />
                    </Tooltip>
                  }
                  leftContent={
                    <>
                      {widgetContext?.draggable && <PanelDragHandle />}
                      {widgetContext?.collapsable && (
                        <PanelFrameworkCollapseExpandButton
                          isCollapsed={widgetContext.isCollapsedVert}
                          onClick={() =>
                            widgetContext.toggleCollapsedVert(
                              !widgetContext.isCollapsedVert,
                            )
                          }
                          disabled={
                            isLoadingInitial ||
                            isLoadingPage ||
                            isEnteringEditMode
                          }
                        />
                      )}
                      {typeof list?.metadata.formula === 'string' && (
                        <FormulaButton
                          onClick={
                            formulasTabsState.isOpen &&
                            formulasTabsState.tab === 'list'
                              ? formulasTabsState.closeFormula
                              : formulasTabsState.openListFormula
                          }
                          disabled={
                            isLoadingInitial ||
                            isLoadingPage ||
                            isEnteringEditMode
                          }
                          isActive={
                            formulasTabsState.isOpen &&
                            formulasTabsState.tab === 'list'
                          }
                          icon={versionsApi.isLiveActuals ? 'ax' : 'fx'}
                        />
                      )}
                      {!isVersionPage && (
                        <ModelExplorerVersionSelector
                          entityId={listId}
                          entityTitle={titleText}
                          disabled={widgetContext?.boardInDesignMode}
                        />
                      )}
                    </>
                  }
                  rightContent={
                    <>
                      {showPeriodSelector && (
                        <Box ml={1}>{periodSelectorComponent}</Box>
                      )}
                      {versionSelectorProps.enableLocalVersionSelector && (
                        <Box ml={1}>
                          <SingleVersionSelector
                            onChange={versionSelectorProps.onChangeLocalVersion}
                            value={versionSelectorProps.localVersion}
                            options={versionSelectorProps.localVersionOptions}
                            overridesGlobal={
                              versionSelectorProps.localVersionOverridesGlobal
                            }
                            disabled={
                              isLoadingInitial ||
                              isLoadingPage ||
                              widgetContext?.boardInDesignMode ||
                              isEnteringEditMode
                            }
                          />
                        </Box>
                      )}
                      <FintasticThemeProvider applyLegacyTheme={false}>
                        {editButton}
                      </FintasticThemeProvider>
                    </>
                  }
                />
                <FormulaCollapse isOpen={formulasTabsState.isOpen}>
                  <FormulaEditorRouter
                    tab={formulasTabsState.tab}
                    column={formulasTabsState.column}
                    onRequestClose={formulasTabsState.closeFormula}
                    versionId={versionId}
                    versionEditable={versionEditable}
                    listFormula={list?.metadata.formula}
                    columns={calculatedColumnsMap}
                    listConnectedTable={listAsConnectedTable}
                    formulaEditorTitle={
                      versionsApi.isLiveActuals ? 'Actuals Formula' : undefined
                    }
                    readonly={isVersionPage || !versionEditable}
                    controlledMode={isVersionPage}
                    clientOnlyMapping={null}
                    controlledFormulaErrorForListItself={null}
                    controlledFormulaErrorForColumns={null}
                  />
                </FormulaCollapse>
              </>
            }
            bottomBar={
              widgetContext?.isCollapsedVert
                ? null
                : !isLoadingInitial && (
                    <PanelBottomBar>
                      <Typography variant="body2" sx={{ mr: 'auto', ml: 1 }}>
                        {`${(page - 1) * itemsPerPage + 1} - ${Math.min(
                          (page - 1) * itemsPerPage + itemsPerPage,
                          totalItems,
                        )}`}{' '}
                        of {totalItems}
                      </Typography>
                      {isLoadingPage && (
                        <CircularProgress
                          size={16}
                          color="primary"
                          sx={{ mx: 1 }}
                        />
                      )}
                      <Box mr={1}>
                        <SelectedCellAggregation />
                      </Box>
                      <Pagination
                        size="small"
                        shape="rounded"
                        color="primary"
                        page={page}
                        count={Math.ceil(totalItems / itemsPerPage)}
                        onChange={(_, page) => setPage(page)}
                        disabled={isLoadingPage || isEnteringEditMode}
                      />
                    </PanelBottomBar>
                  )
            }
          >
            {!widgetContext?.isCollapsedVert &&
            renderColumnColorSelector &&
            columnColors ? (
              <GridColumnColorsCssWrapper
                columnColors={columnColors}
                periods={allPeriodsList}
              >
                {grid}
              </GridColumnColorsCssWrapper>
            ) : (
              grid
            )}
          </Panel>
        </SettingsPanelContextsWrapper>
      </FormulasTabContextProvider>
    </SelectedCellAggregationProvider>
  )
}
